// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file orders/feedback_on_order.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message app.dealzy.FeedbackOnOrderRequest
 */
export class FeedbackOnOrderRequest extends Message<FeedbackOnOrderRequest> {
  /**
   * @generated from field: int32 order_id = 1;
   */
  orderId = 0;

  /**
   * @generated from field: string feedback = 2;
   */
  feedback = "";

  constructor(data?: PartialMessage<FeedbackOnOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.FeedbackOnOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "feedback", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedbackOnOrderRequest {
    return new FeedbackOnOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedbackOnOrderRequest {
    return new FeedbackOnOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedbackOnOrderRequest {
    return new FeedbackOnOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FeedbackOnOrderRequest | PlainMessage<FeedbackOnOrderRequest> | undefined, b: FeedbackOnOrderRequest | PlainMessage<FeedbackOnOrderRequest> | undefined): boolean {
    return proto3.util.equals(FeedbackOnOrderRequest, a, b);
  }
}

