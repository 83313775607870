// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gift_cards/get_gift_cards.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { GiftCard } from "./common_pb.js";

/**
 * @generated from message app.dealzy.GetGiftCardsRequest
 */
export class GetGiftCardsRequest extends Message<GetGiftCardsRequest> {
  /**
   * Optional
   *
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * Optional, 1-indexed
   *
   * @generated from field: int32 page = 2;
   */
  page = 0;

  /**
   * Optional
   *
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<GetGiftCardsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.GetGiftCardsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGiftCardsRequest {
    return new GetGiftCardsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGiftCardsRequest {
    return new GetGiftCardsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGiftCardsRequest {
    return new GetGiftCardsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGiftCardsRequest | PlainMessage<GetGiftCardsRequest> | undefined, b: GetGiftCardsRequest | PlainMessage<GetGiftCardsRequest> | undefined): boolean {
    return proto3.util.equals(GetGiftCardsRequest, a, b);
  }
}

/**
 * @generated from message app.dealzy.GetGiftCardsResponse
 */
export class GetGiftCardsResponse extends Message<GetGiftCardsResponse> {
  /**
   * @generated from field: repeated app.dealzy.GiftCard gift_cards = 1;
   */
  giftCards: GiftCard[] = [];

  /**
   * @generated from field: int32 total_count = 2;
   */
  totalCount = 0;

  constructor(data?: PartialMessage<GetGiftCardsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.GetGiftCardsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gift_cards", kind: "message", T: GiftCard, repeated: true },
    { no: 2, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGiftCardsResponse {
    return new GetGiftCardsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGiftCardsResponse {
    return new GetGiftCardsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGiftCardsResponse {
    return new GetGiftCardsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGiftCardsResponse | PlainMessage<GetGiftCardsResponse> | undefined, b: GetGiftCardsResponse | PlainMessage<GetGiftCardsResponse> | undefined): boolean {
    return proto3.util.equals(GetGiftCardsResponse, a, b);
  }
}

