// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gift_cards/common.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Brand, PriceType } from "../common/common_pb.js";

/**
 * @generated from message app.dealzy.GiftCard
 */
export class GiftCard extends Message<GiftCard> {
  /**
   * @generated from field: app.dealzy.Brand brand = 1;
   */
  brand?: Brand;

  /**
   * @generated from field: app.dealzy.PriceType price_type = 2;
   */
  priceType = PriceType.UNKNOWN;

  /**
   * @generated from field: float price_min = 3;
   */
  priceMin = 0;

  /**
   * @generated from field: float price_max = 4;
   */
  priceMax = 0;

  /**
   * @generated from field: repeated float price_denominations = 5;
   */
  priceDenominations: number[] = [];

  /**
   * @generated from field: string tnc = 6;
   */
  tnc = "";

  /**
   * DateTime format as string
   *
   * @generated from field: string created_at = 7;
   */
  createdAt = "";

  /**
   * @generated from field: bool purchase_limit_reached = 8;
   */
  purchaseLimitReached = false;

  constructor(data?: PartialMessage<GiftCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.GiftCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "message", T: Brand },
    { no: 2, name: "price_type", kind: "enum", T: proto3.getEnumType(PriceType) },
    { no: 3, name: "price_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "price_max", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "price_denominations", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 6, name: "tnc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "purchase_limit_reached", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftCard {
    return new GiftCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftCard {
    return new GiftCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftCard {
    return new GiftCard().fromJsonString(jsonString, options);
  }

  static equals(a: GiftCard | PlainMessage<GiftCard> | undefined, b: GiftCard | PlainMessage<GiftCard> | undefined): boolean {
    return proto3.util.equals(GiftCard, a, b);
  }
}

