import React, { useState } from 'react'

interface Tab {
  id: number
  title: string
  content: React.ReactNode
}

interface TabsProps {
  tabs: Tab[]
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(tabs[0].id)

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${
              activeTab === tab.id ? 'border-b-2 border-orange-500' : ''
            } flex-1 items-center p-4 text-sm font-medium leading-5 text-gray-500 focus:outline-none`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div>{tabs.find((tab) => tab.id === activeTab)?.content}</div>
    </div>
  )
}

export default Tabs
