import { useContext, useState } from 'react'
import ProfileHeader from './ProfileHeader'
import { ScrollArea } from '@dealzy/components/ui/ui/scroll-area'
import { Button } from '@dealzy/components/ui/ui/button'
import LogoutModal from '@dealzy/components/LogoutModal'

import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@dealzy/components/ui/ui/card'
import { useNavigate } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@dealzy/components/ui/ui/dialog'
import { AuthContext } from '@dealzy/AuthContext'

const AuthProfile = () => {
  //Component Hooks
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  //Component States
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)
  //Component Methods

  const confirmSupport = () => {
    const commitHash = import.meta.env.VITE_COMMIT_HASH
    const subject = 'Dealzy Support Query'
    const body = `User ID: ${user?.user_id}\nApp version: ${commitHash}\n\n`
    const params = new URLSearchParams({
      subject,
      body,
    })
    window.open('mailto:support@dealzy.app?' + params.toString())
    setIsSupportModalOpen(false)
  }

  const cancelSupport = () => {
    setIsSupportModalOpen(false)
  }

  const handleLogoutWithConfirmation = () => {
    setIsLogoutModalOpen(true)
  }

  const cancelLogout = () => {
    setIsLogoutModalOpen(false)
  }
  //Component JSX
  return (
    <>
      <div className="flex h-full w-full flex-col items-start justify-start bg-background px-[20px] py-[30px]">
        <ProfileHeader />
        <ScrollArea className="mt-[20px] flex w-full flex-1">
          <div className="grid w-full grid-cols-1 gap-2 md:grid-cols-2">
            {/* <Card className="h-[110px] w-full">
            <CardHeader>
              <CardTitle className="text-xl">Your Cards</CardTitle>
              <CardDescription className="text-xs">
                Manage your cards
              </CardDescription>
            </CardHeader>
          </Card> */}
            <Card
              className="h-[110px] w-full"
              onClick={() => navigate('/my-orders')}
            >
              <CardHeader>
                <CardTitle className="text-xl">Transactions</CardTitle>
                <CardDescription className="text-xs">
                  View your transactions
                </CardDescription>
              </CardHeader>
            </Card>
            <Card
              className="h-[110px] w-full"
              onClick={() => setIsSupportModalOpen(true)}
            >
              <CardHeader>
                <CardTitle className="text-xl">Support</CardTitle>
                <CardDescription className="text-xs">
                  Click here to contact support
                </CardDescription>
              </CardHeader>
            </Card>
          </div>
        </ScrollArea>
        <Button
          variant="destructive"
          className="w-full"
          onClick={handleLogoutWithConfirmation}
        >
          Logout
        </Button>
        <LogoutModal open={isLogoutModalOpen} onClose={cancelLogout} />
      </div>
      <Dialog
        open={isSupportModalOpen}
        onOpenChange={(value) => (value === false ? cancelSupport() : null)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Contact Support</DialogTitle>
            <DialogDescription>
              This will open an email draft to <b>support@dealzy.app</b>. Our
              team will get back to you at the earliest
            </DialogDescription>
          </DialogHeader>
          <div className="flex w-full flex-row items-center justify-between gap-x-2">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => cancelSupport()}
            >
              Cancel
            </Button>
            <Button className="w-full" onClick={() => confirmSupport()}>
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AuthProfile
