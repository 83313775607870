import { useLocation, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { AuthContext } from '@dealzy/AuthContext'
import { Button } from './components/ui/ui/button'
import { ArrowLeft } from 'lucide-react'
import LoginModal from './pages/LoginModal'
import { IoMdLogIn } from 'react-icons/io'
import { RiAccountCircleFill } from 'react-icons/ri'

interface BackState {
  scrollToBrand?: number
}

interface Props {
  children: React.ReactNode
  backTo?: string
  backState?: BackState
}

const Base: React.FC<Props> = ({ children, backTo, backState }) => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const [loginModal, setLoginModal] = useState(false)

  return (
    <div className="flex h-screen flex-col">
      <div className="h-22 flex flex-row items-center justify-between border-b-2 border-orange-300 bg-white px-4 py-2 shadow-sm shadow-gray-200">
        <div className="flex flex-row items-center justify-start">
          {backTo !== undefined ? (
            <BackButton backTo={backTo} backState={backState} />
          ) : (
            <img className="h-[50px]" src="/logo-transparent.png" />
          )}
          <div>
            <div className="text-xl font-bold">Dealzy</div>
            <div className="text-[12px] text-gray-700">
              Instant discounts everytime you shop!
            </div>
          </div>
        </div>
        {location.pathname !== '/profile' && (
          <Button
            variant="outline"
            className="h-[35px] w-[35px] rounded-full p-0"
            onClick={() => {
              if (user) {
                navigate('/profile')
              } else {
                setLoginModal(true)
              }
            }}
          >
            {!user ? (
              <IoMdLogIn className="aspect-square h-auto w-full text-teal-500" />
            ) : (
              <RiAccountCircleFill className="aspect-square h-auto w-full text-teal-500" />
            )}
          </Button>
        )}
      </div>

      <main className="flex-grow overflow-y-auto bg-stone-50">{children}</main>
      <LoginModal open={loginModal} onClose={() => setLoginModal(false)} />
    </div>
  )
}

interface BackButtonProps {
  backTo?: string
  backState?: BackState
}

const BackButton: React.FC<BackButtonProps> = ({ backTo, backState }) => {
  const navigate = useNavigate()
  const handleBackClick = () => {
    if (backTo === undefined) {
      navigate(-1)
    } else {
      navigate(backTo, { state: backState })
    }
  }

  return (
    <Button
      variant="ghost"
      onClick={handleBackClick}
      className="m-0 h-[50px] w-[50px] rounded-full p-0"
    >
      <ArrowLeft className="h-auto w-[30px]" />
      <span className="sr-only">Back Button</span>
    </Button>
  )
}

export default Base
