import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import './index.css'

import App from '@dealzy/App.tsx'
import { AuthProvider } from '@dealzy/AuthContext.tsx'
import { BuyGiftCardPage } from '@dealzy/pages/BuyGiftCardPage'
import { OrderPage } from '@dealzy/pages/OrderPage'
import { ProfilePage } from '@dealzy/pages/Profile/ProfilePage'
import { HomePage } from '@dealzy/pages/home/HomePage'
import Transactions from './pages/Profile/components/Transactions'

import * as Sentry from '@sentry/react'

Sentry.init({
  environment: import.meta.env.VITE_APP_ENV,
  dsn: 'https://f8ffbc8653a85302c57938ba1f56674e@o4507236422975488.ingest.de.sentry.io/4507236425793616',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/product/:brandId', element: <BuyGiftCardPage /> },
      { path: '/profile', element: <ProfilePage /> },
      { path: '/my-orders', element: <Transactions /> },
      { path: '/my-orders/:orderId', element: <OrderPage /> },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
)
