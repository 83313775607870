// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file orders/verify_payment_and_create_order.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message app.dealzy.VerifyPaymentAndCreateOrderRequest
 */
export class VerifyPaymentAndCreateOrderRequest extends Message<VerifyPaymentAndCreateOrderRequest> {
  /**
   * @generated from field: int64 order_id = 1;
   */
  orderId = protoInt64.zero;

  /**
   * @generated from field: string razorpay_payment_id = 3;
   */
  razorpayPaymentId = "";

  /**
   * @generated from field: string razorpay_payment_signature = 4;
   */
  razorpayPaymentSignature = "";

  constructor(data?: PartialMessage<VerifyPaymentAndCreateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.VerifyPaymentAndCreateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "razorpay_payment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "razorpay_payment_signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyPaymentAndCreateOrderRequest {
    return new VerifyPaymentAndCreateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyPaymentAndCreateOrderRequest {
    return new VerifyPaymentAndCreateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyPaymentAndCreateOrderRequest {
    return new VerifyPaymentAndCreateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyPaymentAndCreateOrderRequest | PlainMessage<VerifyPaymentAndCreateOrderRequest> | undefined, b: VerifyPaymentAndCreateOrderRequest | PlainMessage<VerifyPaymentAndCreateOrderRequest> | undefined): boolean {
    return proto3.util.equals(VerifyPaymentAndCreateOrderRequest, a, b);
  }
}

/**
 * @generated from message app.dealzy.VerifyPaymentAndCreateOrderResponse
 */
export class VerifyPaymentAndCreateOrderResponse extends Message<VerifyPaymentAndCreateOrderResponse> {
  constructor(data?: PartialMessage<VerifyPaymentAndCreateOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.VerifyPaymentAndCreateOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyPaymentAndCreateOrderResponse {
    return new VerifyPaymentAndCreateOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyPaymentAndCreateOrderResponse {
    return new VerifyPaymentAndCreateOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyPaymentAndCreateOrderResponse {
    return new VerifyPaymentAndCreateOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyPaymentAndCreateOrderResponse | PlainMessage<VerifyPaymentAndCreateOrderResponse> | undefined, b: VerifyPaymentAndCreateOrderResponse | PlainMessage<VerifyPaymentAndCreateOrderResponse> | undefined): boolean {
    return proto3.util.equals(VerifyPaymentAndCreateOrderResponse, a, b);
  }
}

