import { useMutation } from '@connectrpc/connect-query'
import { AuthContext } from '@dealzy/AuthContext'
import { Button } from '@dealzy/components/ui/ui/button'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  Drawer,
  DrawerFooter,
} from '@dealzy/components/ui/ui/drawer'
import { Input } from '@dealzy/components/ui/ui/input'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@dealzy/components/ui/ui/input-otp'
import { login, requestOTP } from '@dealzy/gen/api-DealzyService_connectquery'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Loader } from 'lucide-react'
import { useToast } from '@dealzy/components/ui/ui/use-toast'

const LoginModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  //Component Hooks
  const { handleLogin } = useContext(AuthContext)
  const { toast } = useToast()

  //Component States
  const [mode, setMode] = useState<'REQUEST_OTP' | 'VERIFY_OTP'>('REQUEST_OTP')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOTP] = useState('')
  const [resendTimer, setResendTimer] = useState<number | null>(null)
  //Component Methods
  useEffect(() => {
    if (mode === 'VERIFY_OTP') {
      // Set initial timer to 60 seconds
      setResendTimer(60)

      // Decrement timer every second
      const intervalId = setInterval(() => {
        setResendTimer((prevTimer) => (prevTimer ? prevTimer - 1 : null))
      }, 1000)

      if (!open) {
        setMode('REQUEST_OTP')
        setPhoneNumber('')
        setOTP('')
      }
      // Clear the timer when exiting VERIFY_OTP mode or upon component unmount
      return () => clearInterval(intervalId)
    }
  }, [mode, open])

  const requestOTPMutation = useMutation(requestOTP, {
    onSuccess: () => {
      setMode('VERIFY_OTP')
    },
    onError: (e) => {
      toast({
        title: 'Error',
        description: e.rawMessage,
        variant: 'destructive',
      })
    },
  })

  const loginMutation = useMutation(login, {
    onSuccess: (data) => {
      handleLogin(data.token)
      onClose()
    },
    onError: (e) => {
      toast({
        title: 'Error',
        description: e.rawMessage,
        variant: 'destructive',
      })
    },
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '')
    setPhoneNumber(value)
  }

  const handleContinue = (e: FormEvent) => {
    e.preventDefault()
    if (mode === 'REQUEST_OTP' && phoneNumber.length !== 10) {
      toast({
        title: 'Error',
        description: 'Phone number must be 10 digits long',
        variant: 'destructive',
      })
    } else if (mode === 'REQUEST_OTP' && phoneNumber.length === 10) {
      requestOTPMutation.mutate({ phoneNumber })
    } else if (mode === 'VERIFY_OTP' && otp.length === 6) {
      loginMutation.mutate({ phoneNumber, otp, googleAccessToken: '' })
    }
  }

  const handleSwitchMode = () => {
    setMode(mode === 'REQUEST_OTP' ? 'VERIFY_OTP' : 'REQUEST_OTP')
  }

  const handleResendOTP = () => {
    requestOTPMutation.mutate({ phoneNumber })
    setResendTimer(60) // Reset the resend timer
  }
  //Component JSX
  return (
    <>
      <Drawer open={open} onClose={onClose} dismissible={false}>
        <DrawerContent className="h-screen">
          <DrawerHeader className="flex flex-row items-center justify-start">
            <img className="h-[70px]" src="/logo-transparent.png" />
            <div className="ml-[10px] flex w-full flex-col items-start justify-center">
              <DrawerTitle className="text-2xl font-semibold">
                Dealzy Login
              </DrawerTitle>
              <DrawerDescription>
                {mode === 'REQUEST_OTP'
                  ? 'Login with your phone number'
                  : `OTP sent to +91 ${phoneNumber}`}
              </DrawerDescription>
            </div>
          </DrawerHeader>
          <div className="h-full">
            <form
              onSubmit={handleContinue}
              className="flex h-full flex-col px-[20px]"
            >
              <div className="flex h-full max-h-full flex-grow flex-col items-center justify-center">
                {' '}
                {mode === 'REQUEST_OTP' ? (
                  <Input
                    placeholder="Enter your phone number"
                    size={10}
                    prefix="+91"
                    maxLength={10}
                    type="number"
                    value={phoneNumber}
                    onChange={handleInputChange}
                    autoFocus
                  />
                ) : (
                  <div className="flex w-full flex-row items-center justify-center">
                    <InputOTP
                      maxLength={6}
                      value={otp}
                      onChange={(value) => setOTP(value)}
                      autoFocus
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </div>
                )}
                <div className="flex h-[50px] w-full flex-row items-center justify-center">
                  {mode === 'REQUEST_OTP' ? (
                    <Button
                      type="button"
                      className="ml-[5px] px-0"
                      variant="link"
                      onClick={handleSwitchMode}
                      disabled={phoneNumber.length !== 10}
                    >
                      Already have an OTP?
                    </Button>
                  ) : resendTimer === null ? (
                    <Button
                      className="ml-[5px] px-0"
                      variant="link"
                      onClick={handleResendOTP}
                    >
                      Resend OTP
                    </Button>
                  ) : (
                    <h4>
                      Resend OTP in{' '}
                      <b>{`00:${String(resendTimer).padStart(2, '0')}`}</b>
                    </h4>
                  )}
                </div>
              </div>
              <DrawerFooter className="mb-[30px]">
                <Button
                  type="submit"
                  disabled={
                    loginMutation.isPending || requestOTPMutation.isPending
                  }
                  className="mt-[30px] w-full"
                >
                  {loginMutation.isPending || requestOTPMutation.isPending ? (
                    <Loader className="h-4 w-4 animate-spin" />
                  ) : mode === 'REQUEST_OTP' ? (
                    'Continue with Phone Number'
                  ) : (
                    'Confirm OTP'
                  )}
                </Button>
                {mode === 'REQUEST_OTP' && (
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    className="text-sm text-red-500"
                    onClick={onClose}
                  >
                    Cancel Login
                  </Button>
                )}
              </DrawerFooter>
            </form>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default LoginModal
