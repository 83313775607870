import { AuthContext } from '@dealzy/AuthContext'
import Base from '@dealzy/Base'
import React, { useContext, useEffect } from 'react'
import AuthProfile from './components/AuthProfile'
import UnAuthProfile from './components/UnAuthProfile'

interface ProfilePageProps {
  // Define any props here if needed
}

export const ProfilePage: React.FC<ProfilePageProps> = () => {
  //Component Hooks
  const { user } = useContext(AuthContext)
  useEffect(() => {
    setZohoASAPVisible(true)
    const timer = setTimeout(() => {
      setZohoASAPVisible(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
      setZohoASAPVisible(false)
    }
  }, [])
  //Component States
  //Component Methods
  //Component JSX
  return <Base backTo="/">{user ? <AuthProfile /> : <UnAuthProfile />}</Base>
}

const setZohoASAPVisible = (visible: boolean) => {
  const launcherBox = document.getElementById('zohohc-asap-web-launcherbox')
  if (launcherBox) {
    launcherBox.style.display = visible ? 'flex' : 'none'
  }
}
