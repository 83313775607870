import { Code, ConnectError } from '@connectrpc/connect'
import { TransportProvider, defaultOptions } from '@connectrpc/connect-query'
import { createConnectTransport } from '@connectrpc/connect-web'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'
import React, { ReactNode, createContext } from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'

export interface User {
  user_id: number
  exp: number
}

interface AuthContextProps {
  user: User | null
  handleLogin: (token: string) => void
  handleLogout: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  handleLogin: () => {},
  handleLogout: () => {},
})

const queryClient = new QueryClient({
  defaultOptions: {
    ...defaultOptions,
    queries: {
      ...defaultOptions.queries,
      refetchOnWindowFocus: false, // default: true
    },
  },
})

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const authTokenKey = 'dealzyAuthToken'
  const [token, setToken] = useLocalStorage<string | undefined>(authTokenKey)
  const user: User | null = token ? jwtDecode(token) : null

  const handleLogin = (authToken: string) => {
    setToken(authToken)
  }

  const handleLogout = () => {
    setToken(undefined)
  }

  const finalTransport = createConnectTransport({
    baseUrl: import.meta.env.VITE_APP_API_BASE_URL || '',

    interceptors: [
      (next) => (request) => {
        if (token) request.header.append('auth-token', token)
        return next(request)
          .then((response) => {
            // nothing extra to do in successful case
            return response
          })
          .catch((err: ConnectError) => {
            // handle error
            if (err.code === Code.Unauthenticated) {
              // force a logout if we recieve UNAUTHENTICATED response code
              handleLogout()
            }
            // rethrow for all other cases, nothing else to do
            throw err
          })
      },
    ],
  })

  return (
    <AuthContext.Provider value={{ user, handleLogin, handleLogout }}>
      <TransportProvider transport={finalTransport}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </TransportProvider>
    </AuthContext.Provider>
  )
}
