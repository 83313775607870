import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CategoryGroup } from '@dealzy/categories'
import { GiftCard } from './GiftCard'

interface GiftCardCategoryProps {
  category: CategoryGroup
}

export const GiftCardCategory: React.FC<GiftCardCategoryProps> = ({
  category,
}) => {
  const navigate = useNavigate()
  return (
    <div>
      <div className="sticky top-0 bg-gray-100 px-4 pb-2 pt-4 font-bold text-gray-700">
        {category.name}
      </div>
      <div className="flex flex-col">
        {category.cards.map((card) => (
          <GiftCard
            key={card.brand?.id}
            card={card}
            category={category.name}
            onClick={() =>
              navigate('/product/' + card.brand?.id, {
                state: {
                  scrollToBrand: card.brand?.id,
                },
              })
            }
          />
        ))}
      </div>
    </div>
  )
}
