import React, { useState } from 'react'

export interface RadioOption {
  id: string
  label: string
}

interface RadioProps {
  options: RadioOption[]
  defaultOptionId: string
  onSelect: (id: string) => void
}

const RadioButton: React.FC<RadioProps> = ({
  options,
  defaultOptionId,
  onSelect,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOptionId)

  const handleOptionChange = (id: string) => {
    setSelectedOption(id)
    onSelect(id)
  }

  return (
    <div className="flex w-full overflow-hidden rounded-lg border border-gray-300">
      {options.map((option) => (
        <label
          key={option.id}
          className={`flex-1 cursor-pointer py-2 text-center ${
            selectedOption === option.id
              ? 'bg-orange-500 text-white'
              : 'bg-white text-gray-700 hover:bg-gray-200'
          }`}
        >
          <input
            type="radio"
            className="hidden"
            value={option.id}
            checked={selectedOption === option.id}
            onChange={() => handleOptionChange(option.id)}
          />
          {option.label}
        </label>
      ))}
    </div>
  )
}

export default RadioButton
