// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/common.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum app.dealzy.PaymentInstrument
 */
export enum PaymentInstrument {
  /**
   * @generated from enum value: UPI = 0;
   */
  UPI = 0,

  /**
   * @generated from enum value: CARD = 1;
   */
  CARD = 1,

  /**
   * @generated from enum value: WALLET = 2;
   */
  WALLET = 2,

  /**
   * @generated from enum value: NETBANKING = 3;
   */
  NETBANKING = 3,

  /**
   * @generated from enum value: EMI = 4;
   */
  EMI = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentInstrument)
proto3.util.setEnumType(PaymentInstrument, "app.dealzy.PaymentInstrument", [
  { no: 0, name: "UPI" },
  { no: 1, name: "CARD" },
  { no: 2, name: "WALLET" },
  { no: 3, name: "NETBANKING" },
  { no: 4, name: "EMI" },
]);

/**
 * @generated from enum app.dealzy.OrderFulfiller
 */
export enum OrderFulfiller {
  /**
   * @generated from enum value: OF_UNKNOWN = 0;
   */
  OF_UNKNOWN = 0,

  /**
   * @generated from enum value: OF_HUBBLE = 2;
   */
  OF_HUBBLE = 2,

  /**
   * @generated from enum value: OF_QC = 3;
   */
  OF_QC = 3,

  /**
   * @generated from enum value: OF_VOUCHAGRAM = 4;
   */
  OF_VOUCHAGRAM = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderFulfiller)
proto3.util.setEnumType(OrderFulfiller, "app.dealzy.OrderFulfiller", [
  { no: 0, name: "OF_UNKNOWN" },
  { no: 2, name: "OF_HUBBLE" },
  { no: 3, name: "OF_QC" },
  { no: 4, name: "OF_VOUCHAGRAM" },
]);

/**
 * @generated from enum app.dealzy.DataProvider
 */
export enum DataProvider {
  /**
   * @generated from enum value: DP_UNKNOWN = 0;
   */
  DP_UNKNOWN = 0,

  /**
   * @generated from enum value: DEALZY = 1;
   */
  DEALZY = 1,

  /**
   * @generated from enum value: HUBBLE = 2;
   */
  HUBBLE = 2,

  /**
   * @generated from enum value: QC = 3;
   */
  QC = 3,

  /**
   * @generated from enum value: VOUCHGRAM = 4;
   */
  VOUCHGRAM = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DataProvider)
proto3.util.setEnumType(DataProvider, "app.dealzy.DataProvider", [
  { no: 0, name: "DP_UNKNOWN" },
  { no: 1, name: "DEALZY" },
  { no: 2, name: "HUBBLE" },
  { no: 3, name: "QC" },
  { no: 4, name: "VOUCHGRAM" },
]);

/**
 * @generated from enum app.dealzy.PriceType
 */
export enum PriceType {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: RANGE = 1;
   */
  RANGE = 1,

  /**
   * @generated from enum value: SLAB = 2;
   */
  SLAB = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PriceType)
proto3.util.setEnumType(PriceType, "app.dealzy.PriceType", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "RANGE" },
  { no: 2, name: "SLAB" },
]);

/**
 * @generated from message app.dealzy.DiscountForPaymentInstrument
 */
export class DiscountForPaymentInstrument extends Message<DiscountForPaymentInstrument> {
  /**
   * @generated from field: app.dealzy.PaymentInstrument payment_instrument = 1;
   */
  paymentInstrument = PaymentInstrument.UPI;

  /**
   * @generated from field: float current_discount = 2;
   */
  currentDiscount = 0;

  constructor(data?: PartialMessage<DiscountForPaymentInstrument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.DiscountForPaymentInstrument";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_instrument", kind: "enum", T: proto3.getEnumType(PaymentInstrument) },
    { no: 2, name: "current_discount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscountForPaymentInstrument {
    return new DiscountForPaymentInstrument().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscountForPaymentInstrument {
    return new DiscountForPaymentInstrument().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscountForPaymentInstrument {
    return new DiscountForPaymentInstrument().fromJsonString(jsonString, options);
  }

  static equals(a: DiscountForPaymentInstrument | PlainMessage<DiscountForPaymentInstrument> | undefined, b: DiscountForPaymentInstrument | PlainMessage<DiscountForPaymentInstrument> | undefined): boolean {
    return proto3.util.equals(DiscountForPaymentInstrument, a, b);
  }
}

/**
 * @generated from message app.dealzy.Brand
 */
export class Brand extends Message<Brand> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: app.dealzy.OrderFulfiller order_fulfiller = 21;
   */
  orderFulfiller = OrderFulfiller.OF_UNKNOWN;

  /**
   * @generated from field: string fulfiller_product_id = 22;
   */
  fulfillerProductId = "";

  /**
   * @generated from field: int32 product_id = 17;
   */
  productId = 0;

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string color = 4;
   */
  color = "";

  /**
   * @generated from field: string logo_image = 5;
   */
  logoImage = "";

  /**
   * @generated from field: string banner_image = 6;
   */
  bannerImage = "";

  /**
   * @generated from field: string category = 7;
   */
  category = "";

  /**
   * @generated from field: string subcategory = 8;
   */
  subcategory = "";

  /**
   * @generated from field: int32 popularity = 10;
   */
  popularity = 0;

  /**
   * @generated from field: bool is_listed = 11;
   */
  isListed = false;

  /**
   * @generated from field: repeated app.dealzy.DiscountForPaymentInstrument current_discounts = 20;
   */
  currentDiscounts: DiscountForPaymentInstrument[] = [];

  /**
   * @generated from field: string sku = 9;
   */
  sku = "";

  /**
   * @generated from field: app.dealzy.BrandEnrichedData enriched_data = 13;
   */
  enrichedData?: BrandEnrichedData;

  /**
   * @generated from field: float spread = 15;
   */
  spread = 0;

  /**
   * @generated from field: float take_rate = 16;
   */
  takeRate = 0;

  /**
   * @generated from field: app.dealzy.DataProvider data_provider = 19;
   */
  dataProvider = DataProvider.DP_UNKNOWN;

  constructor(data?: PartialMessage<Brand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.Brand";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "order_fulfiller", kind: "enum", T: proto3.getEnumType(OrderFulfiller) },
    { no: 22, name: "fulfiller_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "product_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "logo_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "banner_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "popularity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "is_listed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "current_discounts", kind: "message", T: DiscountForPaymentInstrument, repeated: true },
    { no: 9, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "enriched_data", kind: "message", T: BrandEnrichedData },
    { no: 15, name: "spread", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 16, name: "take_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "data_provider", kind: "enum", T: proto3.getEnumType(DataProvider) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Brand {
    return new Brand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Brand {
    return new Brand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Brand {
    return new Brand().fromJsonString(jsonString, options);
  }

  static equals(a: Brand | PlainMessage<Brand> | undefined, b: Brand | PlainMessage<Brand> | undefined): boolean {
    return proto3.util.equals(Brand, a, b);
  }
}

/**
 * @generated from message app.dealzy.HowToRedeemForMode
 */
export class HowToRedeemForMode extends Message<HowToRedeemForMode> {
  /**
   * @generated from field: repeated string steps = 1;
   */
  steps: string[] = [];

  constructor(data?: PartialMessage<HowToRedeemForMode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.HowToRedeemForMode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "steps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HowToRedeemForMode {
    return new HowToRedeemForMode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HowToRedeemForMode {
    return new HowToRedeemForMode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HowToRedeemForMode {
    return new HowToRedeemForMode().fromJsonString(jsonString, options);
  }

  static equals(a: HowToRedeemForMode | PlainMessage<HowToRedeemForMode> | undefined, b: HowToRedeemForMode | PlainMessage<HowToRedeemForMode> | undefined): boolean {
    return proto3.util.equals(HowToRedeemForMode, a, b);
  }
}

/**
 * @generated from message app.dealzy.BrandEnrichedData
 */
export class BrandEnrichedData extends Message<BrandEnrichedData> {
  /**
   * deprecated, use validity instead
   *
   * @generated from field: int32 validity_in_days = 1;
   */
  validityInDays = 0;

  /**
   * @generated from field: string validity = 6;
   */
  validity = "";

  /**
   * one of: ONLINE, IN_STORE, ONLINE_AND_IN_STORE
   *
   * @generated from field: string redemption_type = 2;
   */
  redemptionType = "";

  /**
   * @generated from field: repeated string salient_tnc = 3;
   */
  salientTnc: string[] = [];

  /**
   * @generated from field: repeated string tnc = 4;
   */
  tnc: string[] = [];

  /**
   * @generated from field: map<string, app.dealzy.HowToRedeemForMode> how_to_redeem = 5;
   */
  howToRedeem: { [key: string]: HowToRedeemForMode } = {};

  constructor(data?: PartialMessage<BrandEnrichedData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.BrandEnrichedData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "validity_in_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "validity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "redemption_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "salient_tnc", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "tnc", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "how_to_redeem", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: HowToRedeemForMode} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandEnrichedData {
    return new BrandEnrichedData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandEnrichedData {
    return new BrandEnrichedData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandEnrichedData {
    return new BrandEnrichedData().fromJsonString(jsonString, options);
  }

  static equals(a: BrandEnrichedData | PlainMessage<BrandEnrichedData> | undefined, b: BrandEnrichedData | PlainMessage<BrandEnrichedData> | undefined): boolean {
    return proto3.util.equals(BrandEnrichedData, a, b);
  }
}

/**
 * @generated from message app.dealzy.TakeRate
 */
export class TakeRate extends Message<TakeRate> {
  /**
   * @generated from field: int64 take_rate_id = 1;
   */
  takeRateId = protoInt64.zero;

  /**
   * @generated from field: int64 product_id = 2;
   */
  productId = protoInt64.zero;

  /**
   * @generated from field: float take_rate = 3;
   */
  takeRate = 0;

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 4;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 5;
   */
  endDate?: Timestamp;

  constructor(data?: PartialMessage<TakeRate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.TakeRate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "take_rate_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "take_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "start_date", kind: "message", T: Timestamp },
    { no: 5, name: "end_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TakeRate {
    return new TakeRate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TakeRate {
    return new TakeRate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TakeRate {
    return new TakeRate().fromJsonString(jsonString, options);
  }

  static equals(a: TakeRate | PlainMessage<TakeRate> | undefined, b: TakeRate | PlainMessage<TakeRate> | undefined): boolean {
    return proto3.util.equals(TakeRate, a, b);
  }
}

