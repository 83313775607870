import { GiftCard } from '@dealzy/gen/gift_cards/common_pb'

export interface CategoryGroup {
  name: string
  cards: GiftCard[]
}

const labelForCategory = (categoryKey: string): string => {
  const category = categories.find((e) => e.value === categoryKey)
  return category ? category.label : 'Unknown Category'
}

export const groupByCategory = (giftCards: GiftCard[]): CategoryGroup[] => {
  const categoryMap: Record<string, GiftCard[]> = {}

  giftCards.forEach((giftCard) => {
    if (giftCard.brand && giftCard.brand.category) {
      const category = giftCard.brand.category
      if (!categoryMap[category]) {
        categoryMap[category] = []
      }
      categoryMap[category].push(giftCard)
    }
  })

  return Object.entries(categoryMap).map(([category, cards]) => ({
    name: labelForCategory(category),
    cards,
  }))
}

export const categories = [
  { value: 'shopping', label: 'Shopping' },
  { value: 'food_and_drinks', label: 'Food & Drinks' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'beauty', label: 'Beauty' },
  { value: 'grocery', label: 'Grocery' },
  { value: 'home', label: 'Home' },
  { value: 'travel', label: 'Travel' },
  { value: 'lifestyle', label: 'Lifestyle' },
  { value: 'entertainment', label: 'Entertainment' },
  { value: 'jewellery', label: 'Jewellery' },
  { value: 'luxury brands', label: 'Luxury brands' },
  { value: 'kids', label: 'Kids' },
  { value: 'ott_and_music', label: 'OTT & Music' },
]
