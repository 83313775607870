// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file orders/initiate_order.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { PaymentInstrument } from "../common/common_pb.js";

/**
 * @generated from message app.dealzy.InitiateOrderRequest
 */
export class InitiateOrderRequest extends Message<InitiateOrderRequest> {
  /**
   * @generated from field: int64 brand_id = 1;
   */
  brandId = protoInt64.zero;

  /**
   * @generated from field: float amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: int64 quantity = 4;
   */
  quantity = protoInt64.zero;

  /**
   * @generated from field: app.dealzy.PaymentInstrument payment_instrument = 7;
   */
  paymentInstrument = PaymentInstrument.UPI;

  /**
   * @generated from field: bool force_new_order = 6;
   */
  forceNewOrder = false;

  constructor(data?: PartialMessage<InitiateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.InitiateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "payment_instrument", kind: "enum", T: proto3.getEnumType(PaymentInstrument) },
    { no: 6, name: "force_new_order", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateOrderRequest {
    return new InitiateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateOrderRequest {
    return new InitiateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateOrderRequest {
    return new InitiateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateOrderRequest | PlainMessage<InitiateOrderRequest> | undefined, b: InitiateOrderRequest | PlainMessage<InitiateOrderRequest> | undefined): boolean {
    return proto3.util.equals(InitiateOrderRequest, a, b);
  }
}

/**
 * @generated from message app.dealzy.InitiateOrderResponse
 */
export class InitiateOrderResponse extends Message<InitiateOrderResponse> {
  /**
   * If force_new_order is false, order is not always necessarily created
   * Instead service will check if similar order was placed recently, and
   * if it were, then order creation is skipped, order_id is 0 and a warning_message is set
   * otherwise, order is created and order_id is returned
   *
   * @generated from field: int64 order_id = 1;
   */
  orderId = protoInt64.zero;

  /**
   * @generated from field: string razorpay_order_id = 3;
   */
  razorpayOrderId = "";

  /**
   * @generated from field: string warning_message = 4;
   */
  warningMessage = "";

  constructor(data?: PartialMessage<InitiateOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.InitiateOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "razorpay_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateOrderResponse {
    return new InitiateOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateOrderResponse {
    return new InitiateOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateOrderResponse {
    return new InitiateOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateOrderResponse | PlainMessage<InitiateOrderResponse> | undefined, b: InitiateOrderResponse | PlainMessage<InitiateOrderResponse> | undefined): boolean {
    return proto3.util.equals(InitiateOrderResponse, a, b);
  }
}

