import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from './ui/ui/drawer'
import { Input } from './ui/ui/input'
import { Textarea } from './ui/ui/textarea'
import { useMutation, useQuery } from '@connectrpc/connect-query'
import {
  getUser,
  updateUserData,
} from '@dealzy/gen/api-DealzyService_connectquery'
import { AuthContext } from '@dealzy/AuthContext'
import { useToast } from './ui/ui/use-toast'
import { Button } from './ui/ui/button'
import { Loader } from 'lucide-react'

const EditUserDetailsModal = ({
  open,
  onClose,
  edit,
}: {
  open: boolean
  onClose: () => void
  edit: boolean
}) => {
  //Component Hooks
  const { toast } = useToast()
  const { user: userToken } = useContext(AuthContext)
  const drawerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (open) {
      const setHeight = () => {
        if (drawerRef.current) {
          drawerRef.current.style.height = `${window.innerHeight}px`
        }
      }

      setHeight()
      window.addEventListener('resize', setHeight)

      return () => {
        window.removeEventListener('resize', setHeight)
      }
    }
  }, [open])

  const upateUserDataMutation = useMutation(updateUserData, {
    onSuccess: () => {
      toast({ title: 'Success', description: 'User data updated successfully' })
      onClose()
    },
    onError: (e) => {
      toast({
        title: 'Error',
        description: e.rawMessage || 'Error updating user data',
        variant: 'destructive',
      })
    },
  })

  const {
    data: user,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    getUser,
    {
      userId: userToken?.user_id || 0,
    },
    { enabled: userToken !== null },
  )
  //Component States
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [pincode, setPincode] = useState('')
  //Component Methods
  useEffect(() => {
    if (userToken !== null && open === true) {
      refetchUserData()
    }
  }, [userToken, open])

  const refetchUserData = async () => {
    await refetch()
    if (isError === false && user != null) {
      setName(user.name)
      setEmail(user.email)
      setAddress(user.address)
      setPincode(user.pincode)
    }
  }

  const handleSubmitForm = () => {
    upateUserDataMutation.mutate({
      userId: userToken?.user_id || 0,
      name,
      email,
      address,
      pincode,
    })
  }
  //Compnent JSX
  return (
    <Drawer open={open} onClose={onClose} dismissible={false}>
      <DrawerContent ref={drawerRef} className="flex h-screen flex-1 flex-col">
        <DrawerHeader className="mb-[30px]">
          <DrawerTitle>
            {edit ? 'Edit User Details' : 'Enter User Details'}
          </DrawerTitle>
          <DrawerDescription>
            Enter your name, email and legal address
          </DrawerDescription>
        </DrawerHeader>
        <div className="flex w-full flex-grow flex-col items-start justify-start gap-y-4 overflow-auto px-4">
          <Input
            placeholder="Full Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Input
            placeholder="Email Address"
            value={email}
            disabled={user?.emailVerified === true}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Textarea
            placeholder="Address"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />
          <Input
            placeholder="Pincode"
            value={pincode}
            maxLength={6}
            type="number"
            onChange={(event) => setPincode(event.target.value)}
          />
        </div>
        <DrawerFooter>
          <Button
            disabled={upateUserDataMutation.isPending || isLoading || isError}
            onClick={handleSubmitForm}
          >
            {upateUserDataMutation.isPending ? (
              <Loader className="h-4 w-4 animate-spin" />
            ) : edit ? (
              'Confirm Changes'
            ) : (
              'Submit User Details'
            )}
          </Button>
          <Button
            variant="secondary"
            className="text-red-500"
            onClick={onClose}
          >
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default EditUserDetailsModal
