import { useQuery } from '@connectrpc/connect-query'
import Base from '@dealzy/Base'
import { BuyGiftCard } from '@dealzy/components/BuyGiftCard'
import { ReadMore } from '@dealzy/components/ReadMore'
import { RedemptionDetails } from '@dealzy/components/RedemptionDetails'
import { getGiftCard } from '@dealzy/gen/api-DealzyService_connectquery'
import gcFallback from '@dealzy/pages/home/popular/gc-fallback.svg'
import { Loader } from 'lucide-react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface BuyGiftCardPageProps {}

export const BuyGiftCardPage: React.FC<BuyGiftCardPageProps> = () => {
  const params = useParams()
  const navigate = useNavigate()
  const brandId = parseInt(params.brandId || '0')
  const {
    data,
    isLoading,
    error: getGiftCardError,
  } = useQuery(getGiftCard, { brandId: BigInt(brandId) })
  const giftCard = data?.giftCard

  return (
    <Base backTo="/" backState={{ scrollToBrand: brandId }}>
      <div className="pb-8">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center gap-4 py-24">
            <Loader className="h-8 w-8 animate-spin" />
          </div>
        ) : getGiftCardError ? (
          <div className="m-8 flex h-48 flex-col items-center justify-center gap-4 ring ring-stone-300">
            <h2 className="text-lg font-bold text-red-600">
              Oops! Something went wrong.
            </h2>
            <p>
              We're having trouble loading the details for this brand right now.
              Please contact us for more help.
            </p>
            <button
              className="w-full rounded-md border-2 border-gray-400 bg-white py-3 font-bold text-gray-600"
              onClick={() => navigate('/')}
            >
              Go to home page
            </button>
          </div>
        ) : (
          <>
            <img
              src={giftCard?.brand?.logoImage || gcFallback}
              alt="Brand Logo"
              className="m-4 mx-auto aspect-square size-24 rounded-lg shadow-xl"
            />
            <h1 className="mb-4 text-center text-2xl font-bold">
              {giftCard?.brand?.name}
            </h1>
            <ReadMore text={giftCard?.brand?.description} />
            <BuyGiftCard brandId={parseInt(params.brandId || '0')} />
            <RedemptionDetails
              validity_raw={
                giftCard?.brand?.enrichedData?.validity
                  ? `${giftCard?.brand?.enrichedData?.validity} `
                  : 'Check T&C'
              }
              type={giftCard?.brand?.enrichedData?.redemptionType}
              salientTnc={giftCard?.brand?.enrichedData?.salientTnc}
              tnc={giftCard?.brand?.enrichedData?.tnc}
              how_to_redeem={giftCard?.brand?.enrichedData?.howToRedeem}
            />
            {/* <CouponMessage brandName={giftCard?.brand?.name || ''} /> */}
          </>
        )}
      </div>
    </Base>
  )
}
