// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file orders/order_status.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Order } from "./common_pb.js";

/**
 * @generated from message app.dealzy.OrderStatusRequest
 */
export class OrderStatusRequest extends Message<OrderStatusRequest> {
  /**
   * @generated from field: int32 order_id = 1;
   */
  orderId = 0;

  constructor(data?: PartialMessage<OrderStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.OrderStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderStatusRequest {
    return new OrderStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderStatusRequest {
    return new OrderStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderStatusRequest {
    return new OrderStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OrderStatusRequest | PlainMessage<OrderStatusRequest> | undefined, b: OrderStatusRequest | PlainMessage<OrderStatusRequest> | undefined): boolean {
    return proto3.util.equals(OrderStatusRequest, a, b);
  }
}

/**
 * @generated from message app.dealzy.OrderStatusResponse
 */
export class OrderStatusResponse extends Message<OrderStatusResponse> {
  /**
   * @generated from field: app.dealzy.Order order = 1;
   */
  order?: Order;

  /**
   * DateTime format as string, Optional
   *
   * @generated from field: string estimated_delivery = 2;
   */
  estimatedDelivery = "";

  /**
   * @generated from field: bool success = 3;
   */
  success = false;

  /**
   * @generated from field: repeated app.dealzy.CardDetails card_details = 4;
   */
  cardDetails: CardDetails[] = [];

  /**
   * Optional
   *
   * @generated from field: string error_message = 5;
   */
  errorMessage = "";

  /**
   * @generated from field: string payment_status = 6;
   */
  paymentStatus = "";

  /**
   * @generated from field: bool ask_for_feedback = 7;
   */
  askForFeedback = false;

  constructor(data?: PartialMessage<OrderStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.OrderStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order", kind: "message", T: Order },
    { no: 2, name: "estimated_delivery", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "card_details", kind: "message", T: CardDetails, repeated: true },
    { no: 5, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "payment_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "ask_for_feedback", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderStatusResponse {
    return new OrderStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderStatusResponse {
    return new OrderStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderStatusResponse {
    return new OrderStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OrderStatusResponse | PlainMessage<OrderStatusResponse> | undefined, b: OrderStatusResponse | PlainMessage<OrderStatusResponse> | undefined): boolean {
    return proto3.util.equals(OrderStatusResponse, a, b);
  }
}

/**
 * Mainly based on QC's documentation
 * Types of cards:
 *   1. Card Number, Card Pin, Validity - 80% of the we can expect this to be the case
 *   2. Barcode - By default, assume CA128 format, otherwise we can also get codes for multiple formats
 *        Supported formats: CA128, QRCODE, PDF417, INT2OF5, DATAMATRIX, QCGTINBARCODE-32, QCBARCODE-31-V1, TRACK2
 *   3. Activation Code and URL - Some brands give Activation Code and ActivationURL. Use these two in conjunction for final delivery of Gift Card
 * Extra information to show to user
 *   1. balanceEnquiryInstruction - Certain Cards have specific instructions regarding Balance Inquiry, please note the same and plan the UX appropriately
 *   2. specialInstruction - Certain cards have specific instructions, which needs to be communicated to the end user
 *
 * @generated from message app.dealzy.CardDetails
 */
export class CardDetails extends Message<CardDetails> {
  /**
   * @generated from oneof app.dealzy.CardDetails.activation_codes
   */
  activationCodes: {
    /**
     * @generated from field: app.dealzy.CardDetails.NumberPin number_pin = 1;
     */
    value: CardDetails_NumberPin;
    case: "numberPin";
  } | {
    /**
     * @generated from field: app.dealzy.CardDetails.Barcode barcode = 2;
     */
    value: CardDetails_Barcode;
    case: "barcode";
  } | {
    /**
     * @generated from field: app.dealzy.CardDetails.ActivationCode activation_code = 3;
     */
    value: CardDetails_ActivationCode;
    case: "activationCode";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int64 brand_id = 4;
   */
  brandId = protoInt64.zero;

  /**
   * @generated from field: float amount = 5;
   */
  amount = 0;

  /**
   * @generated from field: string balance_enquiry_instruction = 6;
   */
  balanceEnquiryInstruction = "";

  /**
   * @generated from field: string special_instruction = 7;
   */
  specialInstruction = "";

  /**
   * @generated from field: bool is_used = 8;
   */
  isUsed = false;

  constructor(data?: PartialMessage<CardDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.CardDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "number_pin", kind: "message", T: CardDetails_NumberPin, oneof: "activation_codes" },
    { no: 2, name: "barcode", kind: "message", T: CardDetails_Barcode, oneof: "activation_codes" },
    { no: 3, name: "activation_code", kind: "message", T: CardDetails_ActivationCode, oneof: "activation_codes" },
    { no: 4, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "balance_enquiry_instruction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "special_instruction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_used", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardDetails {
    return new CardDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardDetails {
    return new CardDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardDetails {
    return new CardDetails().fromJsonString(jsonString, options);
  }

  static equals(a: CardDetails | PlainMessage<CardDetails> | undefined, b: CardDetails | PlainMessage<CardDetails> | undefined): boolean {
    return proto3.util.equals(CardDetails, a, b);
  }
}

/**
 * @generated from message app.dealzy.CardDetails.LabledString
 */
export class CardDetails_LabledString extends Message<CardDetails_LabledString> {
  /**
   * @generated from field: string label = 1;
   */
  label = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<CardDetails_LabledString>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.CardDetails.LabledString";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardDetails_LabledString {
    return new CardDetails_LabledString().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardDetails_LabledString {
    return new CardDetails_LabledString().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardDetails_LabledString {
    return new CardDetails_LabledString().fromJsonString(jsonString, options);
  }

  static equals(a: CardDetails_LabledString | PlainMessage<CardDetails_LabledString> | undefined, b: CardDetails_LabledString | PlainMessage<CardDetails_LabledString> | undefined): boolean {
    return proto3.util.equals(CardDetails_LabledString, a, b);
  }
}

/**
 * @generated from message app.dealzy.CardDetails.NumberPin
 */
export class CardDetails_NumberPin extends Message<CardDetails_NumberPin> {
  /**
   * @generated from field: app.dealzy.CardDetails.LabledString number = 1;
   */
  number?: CardDetails_LabledString;

  /**
   * @generated from field: app.dealzy.CardDetails.LabledString pin = 2;
   */
  pin?: CardDetails_LabledString;

  /**
   * ISO8601
   *
   * @generated from field: app.dealzy.CardDetails.LabledString validity_till = 3;
   */
  validityTill?: CardDetails_LabledString;

  constructor(data?: PartialMessage<CardDetails_NumberPin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.CardDetails.NumberPin";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "number", kind: "message", T: CardDetails_LabledString },
    { no: 2, name: "pin", kind: "message", T: CardDetails_LabledString },
    { no: 3, name: "validity_till", kind: "message", T: CardDetails_LabledString },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardDetails_NumberPin {
    return new CardDetails_NumberPin().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardDetails_NumberPin {
    return new CardDetails_NumberPin().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardDetails_NumberPin {
    return new CardDetails_NumberPin().fromJsonString(jsonString, options);
  }

  static equals(a: CardDetails_NumberPin | PlainMessage<CardDetails_NumberPin> | undefined, b: CardDetails_NumberPin | PlainMessage<CardDetails_NumberPin> | undefined): boolean {
    return proto3.util.equals(CardDetails_NumberPin, a, b);
  }
}

/**
 * @generated from message app.dealzy.CardDetails.Barcode
 */
export class CardDetails_Barcode extends Message<CardDetails_Barcode> {
  /**
   * @generated from field: map<string, string> formats = 1;
   */
  formats: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CardDetails_Barcode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.CardDetails.Barcode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "formats", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardDetails_Barcode {
    return new CardDetails_Barcode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardDetails_Barcode {
    return new CardDetails_Barcode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardDetails_Barcode {
    return new CardDetails_Barcode().fromJsonString(jsonString, options);
  }

  static equals(a: CardDetails_Barcode | PlainMessage<CardDetails_Barcode> | undefined, b: CardDetails_Barcode | PlainMessage<CardDetails_Barcode> | undefined): boolean {
    return proto3.util.equals(CardDetails_Barcode, a, b);
  }
}

/**
 * @generated from message app.dealzy.CardDetails.ActivationCode
 */
export class CardDetails_ActivationCode extends Message<CardDetails_ActivationCode> {
  /**
   * @generated from field: app.dealzy.CardDetails.LabledString activation_code = 1;
   */
  activationCode?: CardDetails_LabledString;

  /**
   * @generated from field: string activation_url = 2;
   */
  activationUrl = "";

  constructor(data?: PartialMessage<CardDetails_ActivationCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.CardDetails.ActivationCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "activation_code", kind: "message", T: CardDetails_LabledString },
    { no: 2, name: "activation_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardDetails_ActivationCode {
    return new CardDetails_ActivationCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardDetails_ActivationCode {
    return new CardDetails_ActivationCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardDetails_ActivationCode {
    return new CardDetails_ActivationCode().fromJsonString(jsonString, options);
  }

  static equals(a: CardDetails_ActivationCode | PlainMessage<CardDetails_ActivationCode> | undefined, b: CardDetails_ActivationCode | PlainMessage<CardDetails_ActivationCode> | undefined): boolean {
    return proto3.util.equals(CardDetails_ActivationCode, a, b);
  }
}

