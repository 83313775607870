import { PaymentInstrument } from '@dealzy/gen/common/common_pb'
import { GiftCard as GC } from '@dealzy/gen/gift_cards/common_pb'
import React from 'react'
import gcFallback from './gc-fallback.svg'

interface GiftCardProps {
  card: GC
  onClick?: () => void
}

export const GiftCard: React.FC<GiftCardProps> = ({ card, onClick }) => {
  const discount =
    card.brand?.currentDiscounts.find(
      (discount) => discount.paymentInstrument === PaymentInstrument.UPI,
    )?.currentDiscount || 0

  return (
    <div
      className="flex w-28 cursor-pointer snap-start snap-always flex-col gap-1 overflow-x-auto p-2"
      onClick={onClick}
    >
      <img
        className="aspect-square w-full object-cover"
        src={card.brand?.logoImage || gcFallback}
        alt={`${card.brand?.name} Gift Card`}
      />
      {discount && discount !== 0 ? (
        <div className="rounded-lg border border-green-400 bg-green-50 px-2 py-1 text-center text-sm font-semibold text-green-700">
          {discount}% OFF
        </div>
      ) : null}
    </div>
  )
}
