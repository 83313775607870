import React, { useContext, useState } from 'react'
import { CircleAlert, Edit2 } from 'lucide-react'
import { RiAccountCircleFill } from 'react-icons/ri'
import { useQuery } from '@connectrpc/connect-query'
import { getUser } from '@dealzy/gen/api-DealzyService_connectquery'
import { AuthContext } from '@dealzy/AuthContext'
import { Skeleton } from '@dealzy/components/ui/ui/skeleton'
import { Button } from '@dealzy/components/ui/ui/button'
import EditUserDetailsModal from '@dealzy/components/EditUserDetailsModal'
import VerifyEmailModal from '@dealzy/components/VerifyEmailModal'

const ProfileHeader = () => {
  //Component Hooks
  const { user } = useContext(AuthContext)
  const {
    data: userData,
    isLoading: isUserLoading,
    error: getUserError,
    refetch,
  } = useQuery(getUser, {
    userId: user?.user_id || 0,
  })
  //Component States
  const [editProfile, setEditProfile] = useState(false)
  const [verifyEmail, setVerifyEmail] = useState(false)
  //Component Methods
  const handleEditProfile = () => {
    setEditProfile(true)
  }

  const handleCancelEditProfile = () => {
    setEditProfile(false)
    refetch()
  }

  const handleVerifyEmail = () => {
    setVerifyEmail(true)
  }

  const handleCancelVerifyEmail = () => {
    setVerifyEmail(false)
    refetch()
  }
  //Component JSX
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex flex-row items-center justify-start">
        <RiAccountCircleFill className="mr-[10px] h-[50px] w-[50px] text-black" />
        <div className="flex flex-col items-start justify-center">
          {isUserLoading ? (
            getUserError ? (
              <>
                <h3 className="text-lg font-semibold ">-</h3>
                <h4 className="text-sm font-medium text-muted-foreground">
                  +91 -
                </h4>
              </>
            ) : (
              <>
                <Skeleton className="h-[16px] w-[300px]" />
                <Skeleton className="h-[16px] w-[150px]" />
              </>
            )
          ) : (
            <>
              <h3 className="text-lg font-semibold ">
                {userData?.name ? userData.name : '-'}
              </h3>
              <h4 className="text-sm font-medium text-muted-foreground">
                +91 {userData?.phoneNumber}
              </h4>
              {userData?.pincode === null ||
              userData?.name === null ||
              userData?.name === undefined ||
              userData.pincode === undefined ||
              userData.name.length === 0 ||
              userData.pincode.length === 0 ? (
                <Button
                  variant="link"
                  className="m-[-10px] p-[10px] text-red-500"
                  onClick={handleEditProfile}
                >
                  Complete Profile
                </Button>
              ) : userData.emailVerified === false ? (
                <Button
                  variant="link"
                  className="m-[-10px] p-[10px] text-red-500"
                  onClick={handleVerifyEmail}
                >
                  Verify Email Address
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      {userData?.pincode === null ||
      userData?.name === null ||
      userData?.name === undefined ||
      userData.pincode === undefined ||
      userData.name.length === 0 ||
      userData.pincode.length === 0 ? (
        <div className="flex flex-row-reverse">
          <CircleAlert className="text-red-500" />
        </div>
      ) : (
        <Button
          onClick={handleEditProfile}
          className="h-[50px] w-[50px]"
          variant="outline"
        >
          <Edit2 />
        </Button>
      )}
      <EditUserDetailsModal
        open={editProfile}
        onClose={handleCancelEditProfile}
        edit
      />
      <VerifyEmailModal open={verifyEmail} onClose={handleCancelVerifyEmail} />
    </div>
  )
}

export default ProfileHeader
