// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file orders/common.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Brand, OrderFulfiller, PaymentInstrument } from "../common/common_pb.js";

/**
 * @generated from message app.dealzy.Order
 */
export class Order extends Message<Order> {
  /**
   * @generated from field: int32 order_id = 1;
   */
  orderId = 0;

  /**
   * @generated from field: app.dealzy.Brand brand = 2;
   */
  brand?: Brand;

  /**
   * without discount
   *
   * @generated from field: float amount = 3;
   */
  amount = 0;

  /**
   * with discount
   *
   * @generated from field: float final_amount = 9;
   */
  finalAmount = 0;

  /**
   * @generated from field: string order_date = 4;
   */
  orderDate = "";

  /**
   * @generated from field: string status = 5;
   */
  status = "";

  /**
   * DateTime format as string
   *
   * @generated from field: string created_at = 6;
   */
  createdAt = "";

  /**
   * @generated from field: app.dealzy.PaymentInstrument payment_instrument = 7;
   */
  paymentInstrument = PaymentInstrument.UPI;

  /**
   * @generated from field: string is_used = 8;
   */
  isUsed = "";

  constructor(data?: PartialMessage<Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "brand", kind: "message", T: Brand },
    { no: 3, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "final_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "order_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "payment_instrument", kind: "enum", T: proto3.getEnumType(PaymentInstrument) },
    { no: 8, name: "is_used", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order {
    return new Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJsonString(jsonString, options);
  }

  static equals(a: Order | PlainMessage<Order> | undefined, b: Order | PlainMessage<Order> | undefined): boolean {
    return proto3.util.equals(Order, a, b);
  }
}

/**
 * @generated from message app.dealzy.OrderSummary
 */
export class OrderSummary extends Message<OrderSummary> {
  /**
   * @generated from field: int32 brand_id = 1;
   */
  brandId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 orders = 3;
   */
  orders = 0;

  /**
   * @generated from field: int32 orders_completed = 4;
   */
  ordersCompleted = 0;

  /**
   * @generated from field: int32 orders_incomplete = 5;
   */
  ordersIncomplete = 0;

  /**
   * @generated from field: float payments = 6;
   */
  payments = 0;

  /**
   * @generated from field: float payments_verified = 7;
   */
  paymentsVerified = 0;

  /**
   * @generated from field: float payments_fulfilled = 8;
   */
  paymentsFulfilled = 0;

  /**
   * @generated from field: float payments_fulfilled_mismatch = 9;
   */
  paymentsFulfilledMismatch = 0;

  /**
   * @generated from field: float payments_unverified = 10;
   */
  paymentsUnverified = 0;

  constructor(data?: PartialMessage<OrderSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.OrderSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "orders", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "orders_completed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "orders_incomplete", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "payments", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "payments_verified", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "payments_fulfilled", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "payments_fulfilled_mismatch", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "payments_unverified", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderSummary {
    return new OrderSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderSummary {
    return new OrderSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderSummary {
    return new OrderSummary().fromJsonString(jsonString, options);
  }

  static equals(a: OrderSummary | PlainMessage<OrderSummary> | undefined, b: OrderSummary | PlainMessage<OrderSummary> | undefined): boolean {
    return proto3.util.equals(OrderSummary, a, b);
  }
}

/**
 * @generated from message app.dealzy.FailedOrder
 */
export class FailedOrder extends Message<FailedOrder> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int32 order_id = 2;
   */
  orderId = 0;

  /**
   * @generated from field: app.dealzy.OrderFulfiller fulfiller = 11;
   */
  fulfiller = OrderFulfiller.OF_UNKNOWN;

  /**
   * @generated from field: string fulfiller_product_id = 12;
   */
  fulfillerProductId = "";

  /**
   * @generated from field: string brand_name = 4;
   */
  brandName = "";

  /**
   * @generated from field: string qc_status = 5;
   */
  qcStatus = "";

  /**
   * @generated from field: string payment_status = 6;
   */
  paymentStatus = "";

  /**
   * @generated from field: float order_amount = 7;
   */
  orderAmount = 0;

  /**
   * @generated from field: string order_logs = 8;
   */
  orderLogs = "";

  /**
   * @generated from field: string created_at = 9;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 10;
   */
  updatedAt = "";

  constructor(data?: PartialMessage<FailedOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.FailedOrder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "fulfiller", kind: "enum", T: proto3.getEnumType(OrderFulfiller) },
    { no: 12, name: "fulfiller_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "brand_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "qc_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "payment_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "order_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "order_logs", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FailedOrder {
    return new FailedOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FailedOrder {
    return new FailedOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FailedOrder {
    return new FailedOrder().fromJsonString(jsonString, options);
  }

  static equals(a: FailedOrder | PlainMessage<FailedOrder> | undefined, b: FailedOrder | PlainMessage<FailedOrder> | undefined): boolean {
    return proto3.util.equals(FailedOrder, a, b);
  }
}

