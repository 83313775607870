import { SearchIcon } from 'lucide-react'
import { Input } from './ui/ui/input'

interface Props {
  placeholder?: string
  onSearch: (s: string) => void
}

const Search: React.FC<Props> = ({ placeholder, onSearch }) => (
  <div className="bg-background px-2 py-4">
    <Input
      type="search"
      placeholder={placeholder}
      onChange={(event) => onSearch(event.target.value.toLocaleLowerCase())}
      className="h-[50px]"
      startIcon={SearchIcon}
    />
  </div>
)

export default Search
