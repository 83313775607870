// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file auth/request_otp.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message app.dealzy.RequestOTPRequest
 */
export class RequestOTPRequest extends Message<RequestOTPRequest> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<RequestOTPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.RequestOTPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestOTPRequest {
    return new RequestOTPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestOTPRequest {
    return new RequestOTPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestOTPRequest {
    return new RequestOTPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestOTPRequest | PlainMessage<RequestOTPRequest> | undefined, b: RequestOTPRequest | PlainMessage<RequestOTPRequest> | undefined): boolean {
    return proto3.util.equals(RequestOTPRequest, a, b);
  }
}

/**
 * @generated from message app.dealzy.RequestOTPResponse
 */
export class RequestOTPResponse extends Message<RequestOTPResponse> {
  /**
   * DateTime format as string
   *
   * @generated from field: string expires = 1;
   */
  expires = "";

  /**
   * @generated from field: bool success = 2;
   */
  success = false;

  /**
   * Optional
   *
   * @generated from field: string error_message = 3;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<RequestOTPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.RequestOTPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "expires", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestOTPResponse {
    return new RequestOTPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestOTPResponse {
    return new RequestOTPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestOTPResponse {
    return new RequestOTPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestOTPResponse | PlainMessage<RequestOTPResponse> | undefined, b: RequestOTPResponse | PlainMessage<RequestOTPResponse> | undefined): boolean {
    return proto3.util.equals(RequestOTPResponse, a, b);
  }
}

