import { useState } from 'react'

interface Props {
  text: string
}

const TextWithCopy: React.FC<Props> = ({ text }) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = (s: string) => {
    navigator.clipboard.writeText(s)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000) // Reset copied state after 2 seconds
  }

  return (
    <div>
      {text || '-'}
      <button
        className="ml-2 rounded bg-gray-200 px-2 py-1 text-xs text-gray-700"
        onClick={() => copyToClipboard(text || '')}
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
    </div>
  )
}

export default TextWithCopy
