import { useMutation } from '@connectrpc/connect-query'
import {
  feedbackOnOrder,
  rateOrder,
} from '@dealzy/gen/api-DealzyService_connectquery'
import { Loader } from 'lucide-react'
import { useState } from 'react'

interface Props {
  orderId: number
}

export const Feedback: React.FC<Props> = ({ orderId }) => {
  const [score, setScore] = useState<number>()
  const [scoreSubmitted, setScoreSubmitted] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [feedback, setFeedback] = useState<string>('')

  const rateMutation = useMutation(rateOrder, {
    onSuccess: () => {
      setScoreSubmitted(true)
    },
    // TODO better error handling, for now this is not very critical
    onError: console.error,
  })

  const feedbackMutation = useMutation(feedbackOnOrder, {
    onSuccess: () => {
      setFeedbackSubmitted(true)
    },
    // TODO better error handling, for now this is not very critical
    onError: console.error,
  })

  const handleSubmitScore = (_score: number) => {
    setScore(_score)
    rateMutation.mutate({
      orderId,
      npsRating: _score,
    })
  }

  const handleSubmitFeedback = () => {
    feedbackMutation.mutate({
      orderId,
      feedback,
    })
  }

  return (
    !feedbackSubmitted && (
      <div className="flex flex-col gap-2 p-4">
        {scoreSubmitted ? (
          <>
            <p>
              {(score || 0) < 9
                ? 'Thanks for rating. What could we do to improve your experience?'
                : "Thanks for rating. We're glad your experience went well. What did you like the most?"}
            </p>
            <textarea
              rows={4}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Share your detailed feedback..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <button
              className="rounded-lg bg-orange-500 px-4 py-2 text-white hover:bg-orange-600"
              onClick={handleSubmitFeedback}
            >
              Submit
            </button>
          </>
        ) : rateMutation.isPending ? (
          <Loader className="h-4 w-4 animate-spin" />
        ) : (
          <>
            <p>
              How likely are you to recommend Dealzy to a friend or colleague?
            </p>
            <div className="flex w-full">
              {[...Array(11).keys()].map((score) => (
                <button
                  key={score}
                  className={`flex-1 border py-2 hover:bg-blue-400 
                ${score < 6 ? 'border-red-300 bg-red-50' : score < 9 ? 'border-yellow-300 bg-yellow-50' : 'border-green-300 bg-green-50'}`}
                  onClick={() => handleSubmitScore(score)}
                >
                  {score}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    )
  )
}
