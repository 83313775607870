import { PaymentInstrument } from '@dealzy/gen/common/common_pb'
import { Order } from '@dealzy/gen/orders/common_pb'
import dayjs from 'dayjs'
import React from 'react'

interface OrderCardProps {
  order: Order
  handleOrderClick?: (orderId: number) => void
}

export const OrderCard: React.FC<OrderCardProps> = ({
  order,
  handleOrderClick,
}) => {
  const statusTextColor =
    order.status == 'COMPLETE'
      ? 'text-green-500'
      : order.status == 'FAILED'
        ? 'text-red-500'
        : 'text-orange-500'
  return (
    <div
      className="flex items-center gap-4 border-b p-4"
      onClick={() => handleOrderClick && handleOrderClick(order.orderId)}
    >
      <div>
        <img
          className="aspect-square size-24"
          src={order.brand?.logoImage}
          alt={order.brand?.name}
        />
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-lg font-bold">{order.brand?.name}</div>
        <div>
          <span className="line-through">₹{order.amount}</span>{' '}
          <span className="font-bold">₹{order.finalAmount}</span>
          <span className="m-1 rounded bg-gray-200 px-2 py-1 text-sm">
            {order.paymentInstrument === PaymentInstrument.CARD
              ? 'Card'
              : order.paymentInstrument === PaymentInstrument.WALLET
                ? 'Wallet'
                : 'UPI'}
          </span>
          <span className={'font-bold ' + statusTextColor}>{order.status}</span>
        </div>
        <div className="text-gray-500">
          {dayjs(order.orderDate).format('DD-MMM-YYYY, hh:mm A')}
        </div>
      </div>
    </div>
  )
}
