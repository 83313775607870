import { Outlet, ScrollRestoration } from 'react-router-dom'
import './App.css'
import { Toaster } from './components/ui/ui/toaster'
import { TooltipProvider } from './components/ui/ui/tooltip'

function App() {
  return (
    <TooltipProvider>
      <Outlet />
      <ScrollRestoration /> <Toaster />
    </TooltipProvider>
  )
}

export default App
