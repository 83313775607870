import Tabs from '@dealzy/components/Tabs'
import { useState } from 'react'
import { AspectRatio } from './ui/ui/aspect-ratio'

interface Props {
  validity_raw?: string
  type?: string
  salientTnc?: string[]
  tnc?: string[]
  how_to_redeem?: { [mode: string]: { steps: string[] } }
}

export const RedemptionDetails: React.FC<Props> = ({
  validity_raw,
  type,
  salientTnc,
  tnc,
  how_to_redeem,
}) => {
  const [fullTnc, setFullTnc] = useState(false)

  const tncToShow = fullTnc ? tnc : salientTnc
  const tncToShowPresent = tncToShow && tncToShow.length > 0

  const htr = Object.entries(how_to_redeem || {}).filter(
    ([, htrfm]) => htrfm.steps.length > 0,
  )
  console.log(htr)
  const htrPresent = htr.length > 0

  return (
    <div className="flex w-full flex-col items-center gap-4 px-8 ">
      <div className="divide flex w-full items-center divide-x rounded-lg bg-white ring-1 ring-stone-300">
        <RedemptionType type={type || ''} />
        <div className="flex flex-1 flex-col items-center gap-4 p-4 text-gray-600">
          <span className="text-4xl">⏲️</span>
          <div className="flex flex-col">
            <span className="font-regular text-center text-sm text-gray-500">
              Validity
            </span>
            <span className="text-center font-semibold text-gray-700">
              {validity_raw}
            </span>
          </div>
        </div>
      </div>
      <AspectRatio ratio={3 / 1} className="overflow-hidden rounded-sm">
        <img
          alt="banner1"
          src="/banner_2.png"
          className="h-full w-full object-cover"
        />
      </AspectRatio>
      <div className="flex w-full flex-col items-center gap-2 rounded-lg bg-white text-gray-600 ring-1 ring-stone-300">
        <Tabs
          tabs={[
            {
              id: 1,
              title: 'Terms & Conditions',
              content: (
                <div className="mx-[15px] flex flex-col gap-4 p-4">
                  {tncToShowPresent ? (
                    <ol className="list-outside list-disc">
                      {tncToShow.map((e) => (
                        <li>{e}</li>
                      ))}
                    </ol>
                  ) : (
                    <div className="w-full py-8 text-center">Not available</div>
                  )}
                  <div>
                    <a
                      className="cursor-pointer text-orange-600 visited:text-purple-600 hover:text-orange-800"
                      onClick={() => setFullTnc((f) => !f)}
                    >
                      {fullTnc ? '⌃ View Salient T&C' : '⌄ View Full T&C'}
                    </a>
                  </div>
                </div>
              ),
            },
            {
              id: 2,
              title: 'How to Redeem',
              content: htrPresent ? (
                <>
                  {htr.map(([mode, { steps }]) => (
                    <>
                      <h1 className="sticky top-0 bg-gray-100 p-2 font-bold text-gray-700">
                        {mode}
                      </h1>
                      <div className="mx-[15px] flex flex-col gap-4 p-4">
                        <HTR steps={steps} />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <div className="w-full py-8 text-center">Not available</div>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

interface RedemptionTypeProps {
  // type: 'IN_STORE' | 'ONLINE' | 'ONLINE_AND_IN_STORE'
  type: string
}

const RedemptionType: React.FC<RedemptionTypeProps> = ({ type }) => (
  <div className="flex flex-1 flex-col items-center gap-4 rounded-lg bg-white p-4 text-gray-600">
    <span className="text-4xl">
      {type === 'IN_STORE' ? '🏪' : type === 'ONLINE' ? '🛒' : '🛍️'}
    </span>
    <div className="flex flex-col">
      <span className="font-regular text-center text-sm text-gray-500">
        Redeemable
        <br />
      </span>
      <span className="text-center font-semibold text-gray-700">
        {type === 'IN_STORE'
          ? 'In-store only'
          : type === 'ONLINE'
            ? 'Online only'
            : type === 'ONLINE_AND_IN_STORE'
              ? 'Online & In-store'
              : 'Check T&C'}
      </span>
    </div>
  </div>
)

interface HTRProps {
  steps: string[]
}

const HTR: React.FC<HTRProps> = ({ steps }) => (
  <ol className="list-outside list-decimal">
    {steps.map((e) => (
      <li>{e}</li>
    ))}
  </ol>
)
