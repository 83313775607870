import { CardDetails_Barcode } from '@dealzy/gen/orders/order_status_pb'
import JsBarcode from 'jsbarcode'
import React from 'react'

type SupportedBarcodeFormat =
  | 'CA128' // supported using jsbarcode
  | 'QRCODE' // not supported yet
  | 'PDF417' // not supported yet
  | 'INT2OF5' // supported using jsbarcode
  | 'DATAMATRIX' // not supported yet
  | 'QCGTINBARCODE-32' // not supported yet
  | 'QCBARCODE-31-V1' // not supported yet
  | 'TRACK2' // not supported yet

interface BarcodeCardProps {
  card: CardDetails_Barcode
}

const barcodeFormatMap = new Map<SupportedBarcodeFormat, string>()
barcodeFormatMap.set('CA128', 'CODE128A')
barcodeFormatMap.set('INT2OF5', 'ITF')

const BarcodeCard: React.FC<BarcodeCardProps> = ({ card }) => {
  const renderBarcodeItem = (format: SupportedBarcodeFormat, value: string) => {
    // Create a temporary <canvas> element for generating the barcode
    const canvas = document.createElement('canvas')
    const jsBarcodeFormat = barcodeFormatMap.get(format) || 'CA128'
    let defaultContent = ''
    try {
      JsBarcode(canvas, value, { format: jsBarcodeFormat })
    } catch (error) {
      defaultContent = value
    }

    return (
      <div key={format}>
        {format}:{' '}
        {defaultContent || (
          <img
            style={{ width: '100%' }}
            src={canvas.toDataURL('image/png')}
            alt={format}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="text-gray-500">Scan to use</div>
      <div>
        {card.formats &&
          Object.entries(card.formats).map(([format, data]) => {
            return renderBarcodeItem(format as SupportedBarcodeFormat, data)
          })}
      </div>
    </div>
  )
}

export default BarcodeCard
