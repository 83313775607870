import React, { useContext } from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from './ui/ui/drawer'
import { AuthContext } from '@dealzy/AuthContext'
import { Button } from './ui/ui/button'
import { useNavigate } from 'react-router-dom'

const LogoutModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  //Component Hooks
  const { handleLogout } = useContext(AuthContext)
  const navigate = useNavigate()
  //Component States
  //Component Methods
  const confirmLogout = () => {
    handleLogout()
    navigate('/')
    onClose()
  }
  //Component JSX
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerContent className="mb-[30px]">
        <DrawerHeader className="mb-10">
          <DrawerTitle>Logout</DrawerTitle>
          <DrawerDescription>
            Are you sure you want to logout?
          </DrawerDescription>
        </DrawerHeader>
        <div className="flex w-full flex-row items-center justify-between gap-x-2 px-[20px]">
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            className="w-full"
            variant="destructive"
            onClick={confirmLogout}
          >
            Confirm
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default LogoutModal
