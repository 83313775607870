import TextWithCopy from '@dealzy/components/TextWithCopy'
import { CardDetails_ActivationCode } from '@dealzy/gen/orders/order_status_pb'

interface Props {
  card: CardDetails_ActivationCode
}

const ActivationCodeCard: React.FC<Props> = ({ card }) => {
  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="text-gray-500">
          {card.activationCode?.label || 'Activation Code'}
        </div>
        <TextWithCopy text={card.activationCode?.value || '-'} />
      </div>
      <div>
        <div className="text-gray-500">Activation URL</div>
        <div>{card.activationUrl || '-'}</div>
      </div>
    </div>
  )
}

export default ActivationCodeCard
