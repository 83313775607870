import React, { useContext, useEffect, useState } from 'react'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from './ui/ui/drawer'
import { useToast } from './ui/ui/use-toast'
import { useMutation, useQuery } from '@connectrpc/connect-query'
import {
  getUser,
  sendVerificationEmail,
  verifyEmail,
} from '@dealzy/gen/api-DealzyService_connectquery'
import { AuthContext } from '@dealzy/AuthContext'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from './ui/ui/input-otp'
import { Button } from './ui/ui/button'
import { Loader } from 'lucide-react'
import { Skeleton } from './ui/ui/skeleton'

const VerifyEmailModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  //Component Hooks
  const { toast } = useToast()
  const { user } = useContext(AuthContext)

  const {
    data: email,
    isLoading,
    isError,
    error,
  } = useQuery(sendVerificationEmail, {}, { enabled: user !== null && open })

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
  } = useQuery(
    getUser,
    {
      userId: user?.user_id,
    },
    { enabled: user !== null && open },
  )

  const verifyOTPMutation = useMutation(verifyEmail, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Email verified successfully',
      })
      onClose()
    },
    onError: (e) => {
      toast({
        title: 'Error',
        description: e.rawMessage,
        variant: 'destructive',
      })
    },
  })
  //Component States
  const [otp, setOtp] = useState('')
  //Component Methods
  useEffect(() => {
    if (isError && open) {
      toast({
        title: 'Error',
        description: error.rawMessage,
        variant: 'destructive',
      })
      onClose()
    }
  }, [isError, email, open])
  //Component JSX
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerContent className="mb-[30px]">
        <DrawerHeader className="mb-[30px]">
          <DrawerTitle>Verify your Email Address</DrawerTitle>
          <DrawerDescription>
            An OTP has been sent to your email address{' '}
            {isUserDataLoading ? (
              <Skeleton />
            ) : isUserDataError ? (
              ''
            ) : (
              userData?.email
            )}
          </DrawerDescription>
        </DrawerHeader>
        <div className="flex w-full flex-row items-center justify-center">
          <InputOTP autoFocus maxLength={6} value={otp} onChange={setOtp}>
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>
        <DrawerFooter>
          <Button
            className="w-full"
            disabled={isLoading || isError}
            onClick={() => verifyOTPMutation.mutate({ otp: otp })}
          >
            {verifyOTPMutation.isPending ? (
              <Loader className="h-4 w-4 animate-spin" />
            ) : (
              'Confirm OTP'
            )}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default VerifyEmailModal
