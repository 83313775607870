// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file api.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { RequestOTPRequest, RequestOTPResponse } from "./auth/request_otp_pb.js";
import { LoginRequest, LoginResponse } from "./auth/login_pb.js";
import { GetUserRequest, GetUserResponse } from "./auth/get_user_pb.js";
import { UpdateUserDataRequest } from "./auth/add_user_data_pb.js";
import { VerifyEmailRequest } from "./auth/verify_email_pb.js";
import { GetGiftCardsRequest, GetGiftCardsResponse } from "./gift_cards/get_gift_cards_pb.js";
import { GetGiftCardRequest, GetGiftCardResponse } from "./gift_cards/get_gift_card_pb.js";
import { InitiateOrderRequest, InitiateOrderResponse } from "./orders/initiate_order_pb.js";
import { VerifyPaymentAndCreateOrderRequest, VerifyPaymentAndCreateOrderResponse } from "./orders/verify_payment_and_create_order_pb.js";
import { OrderStatusRequest, OrderStatusResponse } from "./orders/order_status_pb.js";
import { GetOrdersRequest, GetOrdersResponse } from "./orders/get_orders_pb.js";
import { RateOrderRequest } from "./orders/rate_order_pb.js";
import { FeedbackOnOrderRequest } from "./orders/feedback_on_order_pb.js";
import { UpdateOrderStatusRequest } from "./orders/used_order_pb.js";

/**
 * @generated from rpc app.dealzy.DealzyService.Ping
 */
export const ping = {
  localName: "ping",
  name: "Ping",
  kind: MethodKind.Unary,
  I: Empty,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * Login
 *
 * @generated from rpc app.dealzy.DealzyService.RequestOTP
 */
export const requestOTP = {
  localName: "requestOTP",
  name: "RequestOTP",
  kind: MethodKind.Unary,
  I: RequestOTPRequest,
  O: RequestOTPResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.Login
 */
export const login = {
  localName: "login",
  name: "Login",
  kind: MethodKind.Unary,
  I: LoginRequest,
  O: LoginResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.GetUser
 */
export const getUser = {
  localName: "getUser",
  name: "GetUser",
  kind: MethodKind.Unary,
  I: GetUserRequest,
  O: GetUserResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.UpdateUserData
 */
export const updateUserData = {
  localName: "updateUserData",
  name: "UpdateUserData",
  kind: MethodKind.Unary,
  I: UpdateUserDataRequest,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.SendVerificationEmail
 */
export const sendVerificationEmail = {
  localName: "sendVerificationEmail",
  name: "SendVerificationEmail",
  kind: MethodKind.Unary,
  I: Empty,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.VerifyEmail
 */
export const verifyEmail = {
  localName: "verifyEmail",
  name: "VerifyEmail",
  kind: MethodKind.Unary,
  I: VerifyEmailRequest,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * Window shopping workflow
 *
 * @generated from rpc app.dealzy.DealzyService.GetGiftCards
 */
export const getGiftCards = {
  localName: "getGiftCards",
  name: "GetGiftCards",
  kind: MethodKind.Unary,
  I: GetGiftCardsRequest,
  O: GetGiftCardsResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.GetGiftCard
 */
export const getGiftCard = {
  localName: "getGiftCard",
  name: "GetGiftCard",
  kind: MethodKind.Unary,
  I: GetGiftCardRequest,
  O: GetGiftCardResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * Ordering a card workflow
 *
 * @generated from rpc app.dealzy.DealzyService.InitiateOrder
 */
export const initiateOrder = {
  localName: "initiateOrder",
  name: "InitiateOrder",
  kind: MethodKind.Unary,
  I: InitiateOrderRequest,
  O: InitiateOrderResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.VerifyPaymentAndCreateOrder
 */
export const verifyPaymentAndCreateOrder = {
  localName: "verifyPaymentAndCreateOrder",
  name: "VerifyPaymentAndCreateOrder",
  kind: MethodKind.Unary,
  I: VerifyPaymentAndCreateOrderRequest,
  O: VerifyPaymentAndCreateOrderResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.OrderStatus
 */
export const orderStatus = {
  localName: "orderStatus",
  name: "OrderStatus",
  kind: MethodKind.Unary,
  I: OrderStatusRequest,
  O: OrderStatusResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * Previous orders workflow
 *
 * @generated from rpc app.dealzy.DealzyService.GetOrders
 */
export const getOrders = {
  localName: "getOrders",
  name: "GetOrders",
  kind: MethodKind.Unary,
  I: GetOrdersRequest,
  O: GetOrdersResponse,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.RateOrder
 */
export const rateOrder = {
  localName: "rateOrder",
  name: "RateOrder",
  kind: MethodKind.Unary,
  I: RateOrderRequest,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * @generated from rpc app.dealzy.DealzyService.FeedbackOnOrder
 */
export const feedbackOnOrder = {
  localName: "feedbackOnOrder",
  name: "FeedbackOnOrder",
  kind: MethodKind.Unary,
  I: FeedbackOnOrderRequest,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;

/**
 * Updating a discount Card as used/unused
 *
 * @generated from rpc app.dealzy.DealzyService.UpdateOrderStatus
 */
export const updateOrderStatus = {
  localName: "updateOrderStatus",
  name: "UpdateOrderStatus",
  kind: MethodKind.Unary,
  I: UpdateOrderStatusRequest,
  O: Empty,
  service: {
    typeName: "app.dealzy.DealzyService"
  }
} as const;
