import { useQuery } from '@connectrpc/connect-query'
import { getGiftCards } from '@dealzy/gen/api-DealzyService_connectquery'
import { Loader } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { GiftCard } from './GiftCard'

interface Props {
  search: string
}

const PopularBrands: React.FC<Props> = ({ search }) => {
  const navigate = useNavigate()
  const { data, isLoading, error } = useQuery(getGiftCards, {
    page: 1,
    pageSize: 20,
  })
  const giftCards = data?.giftCards

  // TODO this will be in backend in future
  const filteredGiftCards = giftCards?.filter((card) => {
    if (card.brand?.category.toLocaleLowerCase().indexOf(search) !== -1)
      return true
    if (card.brand?.name.toLocaleLowerCase().indexOf(search) !== -1) return true
    return false
  })

  return (
    <div className="flex flex-col gap-4 bg-background py-2" id="popular">
      <h2 className="px-4 text-2xl font-bold">Popular Brands</h2>
      {isLoading ? (
        <div className="flex h-48 items-center justify-center">
          <Loader className="h-8 w-8 animate-spin" />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center p-6 text-center text-red-500">
          <p className="font-semibold">Error loading brands!</p>
        </div>
      ) : (
        <div className="snap-x snap-mandatory overflow-x-auto">
          <div className="grid grid-flow-col grid-rows-2">
            {filteredGiftCards?.map((card) => (
              <GiftCard
                key={card.brand?.id}
                card={card}
                onClick={() => navigate('/product/' + card.brand?.id)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default PopularBrands
