import { useQuery } from '@connectrpc/connect-query'
import Base from '@dealzy/Base'
import { OrderCard } from '@dealzy/components/OrderCard'
import ActivationCodeCard from '@dealzy/components/activated-cards/ActivationCodeCard'
import BarcodeCard from '@dealzy/components/activated-cards/BarcodeCard'
import NumberPinCard from '@dealzy/components/activated-cards/NumberPinCard'
import { orderStatus } from '@dealzy/gen/api-DealzyService_connectquery'
import { CardDetails } from '@dealzy/gen/orders/order_status_pb'
import React from 'react'
import whatsapp from '/whatsapp.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { Feedback } from '@dealzy/components/Feedback'
import { Loader } from 'lucide-react'

export const OrderPage: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const orderId = parseInt(params.orderId || '0')

  const {
    data: order,
    isLoading,
    isRefetching,
    refetch,
    error,
  } = useQuery(orderStatus, {
    orderId,
  })

  // Check status and refetch indefinitely
  // TODO refetch only 4 times
  if (order?.order?.status === 'PROCESSING') {
    refetch()
  }

  return (
    <Base backTo="/my-orders">
      <div className="flex flex-col bg-white">
        <h2 className="border-b p-4 text-2xl font-bold">Order Details</h2>
        {isLoading || isRefetching ? (
          <div className="flex flex-col items-center justify-center gap-4 py-24">
            <Loader className="h-8 w-8 animate-spin" />
            <p className="text-gray">Checking status...</p>
          </div>
        ) : error || !order?.order ? (
          <div className="m-8 flex h-48 flex-col items-center justify-center gap-4">
            <h2 className="text-lg font-bold text-red-600">
              Oops! Something went wrong.
            </h2>
            <p>Order ID: {orderId}</p>
            <p>
              We're having trouble loading your order details right now. Please
              contact us for more help.
            </p>
            <button
              className="w-full rounded-md border-2 border-gray-400 bg-white py-3 font-bold text-gray-600"
              onClick={() => navigate('/my-orders')}
            >
              Go to orders page
            </button>
          </div>
        ) : (
          <>
            <OrderCard order={order.order} />
            {order.order.status === 'COMPLETE' || false ? (
              <>
                <CardDetailsList
                  brandName={order.order.brand?.name || ''}
                  cardDetailsList={order.cardDetails}
                />
                {order.askForFeedback && (
                  <Feedback orderId={order.order.orderId} />
                )}
              </>
            ) : (
              order.order.status === 'FAILED' && (
                <div className="m-8 flex h-48 flex-col gap-4">
                  <h2 className="text-lg font-bold text-red-600">
                    Oops! Looks like your order failed :(
                  </h2>
                  <p>Order ID: {orderId}</p>
                  <p>Error: {order.errorMessage || 'Unknown error'}</p>
                  <p className="text-gray-700">
                    Don't worry, in case any money has been accidentally
                    deducted, it will be refunded. Please contact Dealzy team to
                    get this resolved.
                  </p>
                </div>
              )
            )}
          </>
        )}
      </div>
    </Base>
  )
}

interface CDLProps {
  brandName: string
  cardDetailsList: CardDetails[]
}

const CardDetailsList: React.FC<CDLProps> = ({
  brandName,
  cardDetailsList,
}) => {
  return (
    <div>
      <div className="border bg-gray-100 px-4 py-2 font-bold text-gray-700">
        <b>{cardDetailsList.length} card(s) activated</b>
      </div>
      <div>
        {cardDetailsList.map((card, idx) => (
          <CardDetailsComponent brandName={brandName} card={card} key={idx} />
        ))}
      </div>
    </div>
  )
}

interface CDProps {
  brandName: string
  card: CardDetails
}

const CardDetailsComponent: React.FC<CDProps> = ({ brandName, card }) => {
  return (
    <div className="flex flex-col gap-4 border-b p-4">
      <div className="text-lg">
        <b>Card worth ₹{card.amount}</b>
      </div>

      {/* Render data based on card type */}
      {card.activationCodes.case === 'numberPin' && (
        <NumberPinCard card={card.activationCodes.value} />
      )}
      {card.activationCodes.case === 'barcode' && (
        <BarcodeCard card={card.activationCodes.value} />
      )}
      {card.activationCodes.case === 'activationCode' && (
        <ActivationCodeCard card={card.activationCodes.value} />
      )}

      {/* WhatsApp share button */}
      {card.activationCodes.case !== 'barcode' && ( // Exclude barcode cards
        <button
          className="mt-2 flex items-center justify-center gap-2 rounded-md bg-green-500 px-4 py-2 text-white hover:bg-green-600"
          onClick={() => shareCardOnWhatsApp(brandName, card)}
        >
          Share on
          <img src={whatsapp} alt="WhatsApp" width="100" />
        </button>
      )}

      {/* Instructions */}
      {card.balanceEnquiryInstruction && (
        <div>
          <b>Balance Enquiry Instructions:</b>
          <div>{card.balanceEnquiryInstruction}</div>
        </div>
      )}
      {card.specialInstruction && card.specialInstruction !== 'null' && (
        <div>
          <b>Special Instructions:</b>
          <div>{card.specialInstruction}</div>
        </div>
      )}
    </div>
  )
}

// Helper function to share a specific card
const shareCardOnWhatsApp = (brandName: string, card: CardDetails) => {
  let message = `Hey, check out this ${brandName} gift card worth ₹${card.amount}. Here's how to activate it:\n\n`

  switch (card.activationCodes.case) {
    case 'numberPin':
      if (card.activationCodes.value.number?.value) {
        message += `*${card.activationCodes.value.number.label || 'Card Number'}:* ${card.activationCodes.value.number.value}\n`
      }
      if (card.activationCodes.value.pin?.value) {
        message += `*${card.activationCodes.value.pin.label || 'Card PIN'}:* ${card.activationCodes.value.pin.value}\n`
      }
      break
    case 'activationCode':
      message += `*${card.activationCodes.value.activationCode?.label || 'Activation Code'}:* ${card.activationCodes.value.activationCode?.value}\n`
      message += `*Activation URL:* ${card.activationCodes.value.activationUrl || '-'}\n`
      break
  }

  message += `\nYou can get your favorite gift cards too from Dealzy: ${import.meta.env.VITE_APP_BASE_URL}`
  const url = `https://wa.me/?text=${encodeURIComponent(message)}`
  window.open(url, '_blank')
}
