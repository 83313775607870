import {create} from 'zustand';
import type {} from '@redux-devtools/extension';

interface BrandStore {
    brandId: number | null
    setBrandId: (brandId: number | null) => void
    selectedAmount: number | null
    setSelectedAmount: (amount: number | null) => void
}

const useBrandStore = create<BrandStore>()(
    (set) =>({
        brandId: null,
        setBrandId: (brandId: number | null) => set({brandId}),
        selectedAmount: null,
        setSelectedAmount: (selectedAmount: number | null) => set({selectedAmount})
    }),
)

export default useBrandStore;