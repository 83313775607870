import { useQuery } from '@connectrpc/connect-query'
import { groupByCategory } from '@dealzy/categories'
import { getGiftCards } from '@dealzy/gen/api-DealzyService_connectquery'
import { Loader } from 'lucide-react'
import { GiftCard } from '@dealzy/gen/gift_cards/common_pb'
import { useEffect } from 'react'
import { GiftCardCategory } from './GiftCardCategory'
import { useLocation } from 'react-router-dom'
import Fuse from 'fuse.js'
interface Props {
  search: string
}

const SaveOnBrands: React.FC<Props> = ({ search }) => {
  const { data, isLoading, error } = useQuery(getGiftCards, {
    page: 1,
    pageSize: 1000,
  })
  const giftCards = data?.giftCards

  const location = useLocation()
  useEffect(() => {
    if (location.state?.scrollToBrand) {
      // scroll to last seen brand
      const element = document.getElementById(
        '#brand-' + location.state.scrollToBrand,
      )
      if (element) {
        element.scrollIntoView({ behavior: 'instant' })
      }
    }
  }, [location.state?.scrollToBrand])

  const prepareDataForSearch = (card: GiftCard): any => {
    const flatDiscounts = card.brand?.currentDiscounts
      .map((discount) => `${discount.currentDiscount}%`)
      .join(', ')

    return {
      ...card,
      brand: {
        ...card.brand,
        flatDiscounts,
      },
      flatPriceDenominations: card.priceDenominations.join(', '),
    }
  }

  const processedGiftCards = giftCards?.map(prepareDataForSearch)

  const fuse = new Fuse(processedGiftCards ? processedGiftCards : [], {
    keys: [
      'brand.name',
      'brand.category',
      'brand.description',
      'flatPriceDenominations',
      'brand.flatDiscounts',
    ],
    threshold: 0.8,
    includeScore: true,
  })

  const filteredGiftCards = search
    ? fuse.search(search).map((result) => result.item)
    : giftCards

  const categories = groupByCategory(filteredGiftCards || [])

  return (
    <div className="flex flex-col gap-4 bg-white pt-3" id="categories">
      <h2 className="px-4 text-2xl font-bold">Save on 100+ Brands</h2>
      {isLoading ? (
        <div className="flex h-48 items-center justify-center">
          <Loader className="h-8 w-8 animate-spin" />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center p-6 text-center text-red-500">
          <p className="font-semibold">Error loading brands!</p>
        </div>
      ) : (
        <div>
          {categories.map((category) => (
            <GiftCardCategory key={category.name} category={category} />
          ))}
        </div>
      )}
    </div>
  )
}

export default SaveOnBrands
