import { PaymentInstrument } from '@dealzy/gen/common/common_pb'
import { GiftCard as GC } from '@dealzy/gen/gift_cards/common_pb'
import React from 'react'

interface GiftCardProps {
  card: GC
  category: string
  onClick?: () => void
}

export const GiftCard: React.FC<GiftCardProps> = ({
  card,
  category,
  onClick,
}) => {
  const discount =
    card.brand?.currentDiscounts.find(
      (discount) => discount.paymentInstrument === PaymentInstrument.UPI,
    )?.currentDiscount || 0

  return (
    <div
      className="flex items-center border-b p-4"
      onClick={onClick}
      id={'#brand-' + card.brand?.id}
    >
      <img
        className="aspect-square size-24 rounded-lg shadow-lg"
        src={card.brand?.logoImage}
        alt={`${card.brand?.name} Gift Card`}
      />
      <div className="flex flex-col p-4">
        <h3 className="text-xl font-bold">{card.brand?.name}</h3>
        <p className="text-md text-gray-500">{category}</p>
        {discount ? (
          <p className="text-md font-bold text-green-600">
            <span className="text-2xl">{discount}</span>% OFF
          </p>
        ) : undefined}
      </div>
    </div>
  )
}
