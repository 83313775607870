import Base from '@dealzy/Base'
import Search from '@dealzy/components/Search'
import { AspectRatio } from '@dealzy/components/ui/ui/aspect-ratio'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@dealzy/components/ui/ui/carousel'
import PopularBrands from '@dealzy/pages/home/popular/PopularBrands'
import SaveOnBrands from '@dealzy/pages/home/save/SaveOnBrands'
import React, { useState } from 'react'

interface Props {
  // Define any props here if needed
}

export const HomePage: React.FC<Props> = () => {
  const [search, setSearch] = useState('')
  return (
    <Base>
      <div className="flex flex-col">
        <div className="relative">
          <Carousel
            className="w-full"
            opts={{ loop: true, duration: 20, active: true }}
          >
            <CarouselContent>
              <CarouselItem>
                <AspectRatio ratio={3 / 1}>
                  <img
                    alt="banner1"
                    src="/banner_1.png"
                    className="h-full w-full object-cover"
                  />
                </AspectRatio>
              </CarouselItem>
              <CarouselItem>
                <img
                  alt="banner2"
                  src="/banner_2.png"
                  className="h-full w-full object-cover"
                />
              </CarouselItem>
            </CarouselContent>
            <CarouselNext className="absolute right-0 top-1/2 -translate-y-1/2 transform" />
            <CarouselPrevious className="absolute left-0 top-1/2 -translate-y-1/2 transform" />
          </Carousel>
        </div>
        <Search
          placeholder="Search brands, categories..."
          onSearch={setSearch}
        />
        {search === '' || search === null ? (
          <PopularBrands search={search} />
        ) : null}
        <SaveOnBrands search={search} />
      </div>
    </Base>
  )
}
