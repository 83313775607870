import { useEffect, useRef, useState } from 'react'

interface ReadMoreProps {
  text?: string
}

export const ReadMore: React.FC<ReadMoreProps> = ({ text }) => {
  const [showMore, setShowMore] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      const current = textRef.current
      if (current) {
        // Assuming line height of 1.6rem and 3 lines, adjust as needed
        const maxHeight = parseFloat(getComputedStyle(current).lineHeight) * 3
        setIsOverflowing(current.scrollHeight > maxHeight)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [text])

  const toggleText = () => {
    setShowMore(!showMore)
  }

  return (
    <div className="flex flex-col justify-center px-8 text-center text-gray-700">
      <div
        ref={textRef}
        className={`whitespace-normal ${showMore ? '' : 'line-clamp-3'}`}
      >
        {text}
      </div>
      {isOverflowing && (
        <button className="text-orange-500" onClick={toggleText}>
          {showMore ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  )
}
