import TextWithCopy from '@dealzy/components/TextWithCopy'
import { CardDetails_NumberPin } from '@dealzy/gen/orders/order_status_pb'
import dayjs from 'dayjs'

interface Props {
  card: CardDetails_NumberPin
}

const NumberPinCard: React.FC<Props> = ({ card }) => {
  return (
    <div className="flex flex-col gap-2">
      {card.number?.value && (
        <div>
          <div className="text-gray-500">
            {card.number?.label || 'Card Number'}
          </div>
          <TextWithCopy text={card.number?.value || ''} />
        </div>
      )}
      {card.pin?.value && (
        <div>
          <div className="text-gray-500">{card.pin?.label || 'Card Pin'}</div>
          <TextWithCopy text={card.pin?.value || ''} />
        </div>
      )}
      {card.validityTill?.value && (
        <div>
          <div className="text-gray-500">
            {card.validityTill?.label || 'Valid Till'}
          </div>
          <div>
            {dayjs(card.validityTill?.value).format('DD-MMM-YYYY, hh:mm A') ||
              '-'}
          </div>
        </div>
      )}
    </div>
  )
}

export default NumberPinCard
