// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file orders/rate_order.proto (package app.dealzy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message app.dealzy.RateOrderRequest
 */
export class RateOrderRequest extends Message<RateOrderRequest> {
  /**
   * @generated from field: int32 order_id = 1;
   */
  orderId = 0;

  /**
   * should be between 0 and 10
   *
   * @generated from field: int32 nps_rating = 2;
   */
  npsRating = 0;

  constructor(data?: PartialMessage<RateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "app.dealzy.RateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "nps_rating", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RateOrderRequest {
    return new RateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RateOrderRequest {
    return new RateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RateOrderRequest {
    return new RateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RateOrderRequest | PlainMessage<RateOrderRequest> | undefined, b: RateOrderRequest | PlainMessage<RateOrderRequest> | undefined): boolean {
    return proto3.util.equals(RateOrderRequest, a, b);
  }
}

