import { useMutation, useQuery } from '@connectrpc/connect-query'
import { AuthContext, User } from '@dealzy/AuthContext'
import RadioButton, { RadioOption } from '@dealzy/components/Radio'
import {
  getGiftCard,
  getUser,
  initiateOrder,
  verifyPaymentAndCreateOrder,
} from '@dealzy/gen/api-DealzyService_connectquery'
import LoginModal from '@dealzy/pages/LoginModal'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import EditUserDetailsModal from './EditUserDetailsModal'
import { useToast } from './ui/ui/use-toast'
import { jwtDecode } from 'jwt-decode'
import { Input } from './ui/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from './ui/ui/select'
import useBrandStore from '@dealzy/lib/state-store/brand-selector-store'
import { PaymentInstrument } from '@dealzy/gen/common/common_pb'

interface BuyGiftCardProps {
  brandId: number
}

export const BuyGiftCard: React.FC<BuyGiftCardProps> = ({ brandId }) => {
  const navigate = useNavigate()
  const { user: userToken } = useContext(AuthContext)
  const { toast } = useToast()
  const cachebrandId = useBrandStore((state) => state.brandId)
  const cacheAmount = useBrandStore((state) => state.selectedAmount)
  const presetDenominations = [500, 1000, 2000, 2500, 5000, 7500, 10000]

  const [amount, setAmount] = useState(0)
  const [error, setError] = useState('')
  const [selectedPaymentInstrument, setSelectedPaymentInstrument] =
    useState<PaymentInstrument>(PaymentInstrument.UPI)
  const [loginModal, setLoginModal] = useState(false)
  const [detailsModal, setDetailsModal] = useState(false)

  const {
    data: user,
    isLoading: isUserLoading,
    error: getUserError,
  } = useQuery(
    getUser,
    { userId: userToken?.user_id || 0 },
    { enabled: userToken !== null },
  )

  const getUserMutation = useMutation(getUser, {
    onSuccess: (data) => {
      if (
        data.pincode === null ||
        data.pincode.length === 0 ||
        data.name === null ||
        data.name.length === 0
      ) {
        handleDetails()
      } else {
        toast({
          title: 'Login Successful',
          description:
            'You are now logged in, you can now proceed to buy the gift card.',
        })
      }
    },
    onError: (e) => {
      console.error(e)
      toast({
        title: 'Error',
        description: e.rawMessage,
        variant: 'destructive',
      })
    },
  })

  const {
    data,
    isLoading: isGiftCardLoading,
    error: getGiftCardError,
  } = useQuery(getGiftCard, { brandId: BigInt(brandId) })

  const isLoading = isUserLoading || isGiftCardLoading
  const giftCard = data?.giftCard
  const denominations = useMemo(
    () =>
      giftCard?.priceDenominations !== undefined
        ? giftCard?.priceDenominations
        : [],
    [giftCard],
  )
  const isSlabMode = denominations.length > 0
  const minAmount = giftCard?.priceMin || 0
  const maxAmount = giftCard?.priceMax || 10000
  const upiDiscountPercentage =
    giftCard?.brand?.currentDiscounts.find(
      (discount) => discount.paymentInstrument === PaymentInstrument.UPI,
    )?.currentDiscount || 0

  const paymentInstrumentLabels: Record<PaymentInstrument, string> = {
    [PaymentInstrument.UPI]: 'UPI',
    [PaymentInstrument.CARD]: 'Card',
    [PaymentInstrument.WALLET]: 'Wallet',
    [PaymentInstrument.EMI]: 'EMI',
    [PaymentInstrument.NETBANKING]: 'Net Banking',
  } as const
  const paymentInstrumentOptions: RadioOption[] =
    giftCard?.brand?.currentDiscounts
      .filter(
        (discount) =>
          discount.currentDiscount > 0 &&
          discount.paymentInstrument !== PaymentInstrument.EMI &&
          discount.paymentInstrument !== PaymentInstrument.NETBANKING,
      )
      .map((discount) => ({
        id: discount.paymentInstrument.toString(),
        label: paymentInstrumentLabels[discount.paymentInstrument],
      })) || []

  const discountPercentage =
    giftCard?.brand?.currentDiscounts.find(
      (discount) => discount.paymentInstrument === selectedPaymentInstrument,
    )?.currentDiscount || 0
  const discountDifference = upiDiscountPercentage - discountPercentage
  const discountedAmount = amount - (amount * discountPercentage) / 100

  const commonDenominations =
    giftCard?.priceDenominations === null ||
    giftCard?.priceDenominations.length === undefined ||
    giftCard?.priceDenominations.length === 0
      ? presetDenominations.filter(
          (denominations) =>
            denominations <= maxAmount && denominations >= minAmount,
        )
      : presetDenominations.filter((denomination) =>
          giftCard?.priceDenominations.includes(denomination),
        )

  // Set default amount in a smart way
  useEffect(() => {
    const isSlabMode = denominations.length > 0
    if (cachebrandId === null && cacheAmount === null) {
      useBrandStore.getState().setBrandId(brandId)
    }
    if (isSlabMode) {
      if (brandId === cachebrandId && cacheAmount !== null) {
        setAmount(cacheAmount)
      } else {
        if (denominations.includes(1000)) setAmount(1000)
        else setAmount(denominations[Math.min(2, denominations.length - 1)])
      }
    } else {
      if (
        data?.giftCard?.brand?.id === 2 &&
        data?.giftCard?.brand?.name === 'Zomato'
      ) {
        setAmount(7500)
      } else {
        if (brandId === cachebrandId && cacheAmount === null) {
          setAmount(Math.max(1000, minAmount))
        } else if (brandId === cachebrandId && cacheAmount !== null) {
          setAmount(cacheAmount)
        } else {
          setAmount(Math.max(1000, minAmount))
        }
      }
    }
  }, [denominations, minAmount])

  const verifyOrderMutation = useMutation(verifyPaymentAndCreateOrder, {
    onSuccess: (_, vars) => {
      useBrandStore.getState().setSelectedAmount(null)
      useBrandStore.getState().setBrandId(null)
      navigate('/my-orders/' + vars.orderId)
    },
    onError: (e) => {
      setError(e.rawMessage)
      toast({
        title: 'Error',
        description: e.rawMessage,
        variant: 'destructive',
      })
    },
  })

  const initiateOrderMutation = useMutation(initiateOrder, {
    onSuccess: (call) => {
      initiateRazorpayPayment(selectedPaymentInstrument, {
        razorpayOrderId: call.razorpayOrderId,
        userFullname: user?.name,
        userContact: user?.phoneNumber,
        userEmail: user?.email,
        userAddress: user?.address,
        userPincode: user?.pincode,
        amountINR: discountedAmount,
        onSuccess: (razorpayPaymentId, razorpayPaymentSignature) => {
          verifyOrderMutation.mutate({
            orderId: call.orderId,
            razorpayPaymentId,
            razorpayPaymentSignature,
          })
        },
        onError: (e: string) => {
          console.error(e)
          setError('Payment failed. Please try again.')
        },
      })
    },
    onError: (e) => {
      setError(e.rawMessage)
    },
  })

  if (brandId === 0) {
    navigate('/')
  }

  const [isAmountValid, setIsAmoutValid] = useState(false)
  useEffect(() => {
    let valid = true
    if (amount < minAmount || amount > maxAmount) {
      valid = false
    } else if (isSlabMode) {
      valid = denominations.includes(amount)
    }
    setIsAmoutValid(valid)
  }, [amount, minAmount, maxAmount, denominations, isSlabMode])

  const handlePlaceOrder = async () => {
    if (!userToken) {
      handleLogin()
    } else {
      getUserMutation
        .mutateAsync({ userId: userToken.user_id })
        .then((userTemp) => {
          if (
            userTemp.pincode === null ||
            userTemp.pincode.length === 0 ||
            userTemp.name === null ||
            userTemp.name.length === 0
          ) {
            handleDetails()
          } else {
            handleRazorpayPayment()
          }
        })
    }
  }

  const handleRazorpayPayment = () => {
    initiateOrderMutation.mutate({
      brandId: BigInt(brandId),
      quantity: BigInt(1), // TODO
      amount,
      paymentInstrument: selectedPaymentInstrument,
      forceNewOrder: true,
    })
  }

  const handleLogin = () => {
    setLoginModal(true)
  }

  const handleCloseLogin = async () => {
    console.log('Mutation called')
    setLoginModal(false)
    const temp = localStorage.getItem('dealzyAuthToken')
    if (temp) {
      const id = jwtDecode<User>(temp).user_id
      getUserMutation.mutate({ userId: id })
    }
  }

  const handleDetails = () => {
    setDetailsModal(true)
  }

  const handleCloseDetails = useCallback(async () => {
    if (detailsModal === true) {
      setDetailsModal(false)
      const temp = localStorage.getItem('dealzyAuthToken')
      if (temp) {
        const id = jwtDecode<User>(temp).user_id
        getUserMutation.mutate({ userId: id })
      }
    }
  }, [getUserMutation, detailsModal])

  return (
    <>
      <div className="m-8 mb-4 flex flex-col items-center gap-2 rounded-lg bg-white p-4 ring-1 ring-stone-300">
        {isSlabMode ? (
          <Select
            value={amount.toString()}
            onValueChange={(value) => {
              setAmount(value ? parseInt(value, 10) : 0)
              useBrandStore.getState().setSelectedAmount(parseInt(value, 10))
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select Amount" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Denominations</SelectLabel>
                {denominations
                  .sort((a, b) => a - b)
                  .map((denomination) => (
                    <SelectItem
                      key={denomination}
                      value={denomination.toString()}
                    >
                      ₹ {denomination}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        ) : (
          <Input
            autoFocus
            type="number"
            value={amount}
            step={500}
            min={minAmount}
            max={maxAmount}
            onChange={(e) => {
              setAmount(parseInt(e.target.value, 10))
              useBrandStore
                .getState()
                .setSelectedAmount(parseInt(e.target.value, 10))
            }}
            prefix="₹"
            className="block flex-1 border border-gray-300 bg-gray-50 px-3 py-2 text-right text-lg font-bold text-gray-900 focus:border-orange-500 focus:ring-orange-500"
            placeholder="1000"
            required
          />
        )}
        <div className="mt-[10px] flex w-full flex-row gap-x-2 overflow-x-auto">
          {commonDenominations.length > 1 ? (
            commonDenominations.map((denomination: number, index: number) => (
              <button
                key={index}
                className="rounded-full border-[1px] border-gray-300 px-[10px] py-[5px]"
                onClick={() => setAmount(denomination)}
              >
                ₹{denomination}
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
        <hr className="my-2 h-px w-full border-0 bg-gray-200" />

        {discountPercentage !== 0 && (
          <>
            <div className="flex w-full justify-between">
              <div className="text-gray-500">Card Value</div>
              <div className="line-through">
                ₹{amount ? amount.toFixed(2) : '-'}
              </div>
            </div>
            <div className="flex w-full justify-between">
              <div className="text-gray-500">
                Dealzy Discount{' '}
                <span className="font-bold text-green-600">
                  ({discountPercentage}% off)
                </span>
              </div>
              <div className="font-bold text-green-500">
                ₹{amount ? (amount - discountedAmount).toFixed(2) : '-'}
              </div>
            </div>
          </>
        )}
        <div className="flex w-full justify-between">
          <div className="text-gray-500">
            {discountPercentage > 0 ? 'You only pay' : 'You pay'}
          </div>
          <div>₹{amount ? discountedAmount.toFixed(2) : '-'}</div>
        </div>

        <hr className="my-2 h-px w-full border-0 bg-gray-200" />

        {error && <div className="text-red-600">{error}</div>}
        {giftCard?.purchaseLimitReached && (
          <div className="text-red-600">Purchase Limit Reached</div>
        )}
        {amount < minAmount || amount > maxAmount ? (
          <div className="text-red-600">
            Amount should be between ₹{minAmount} and ₹{maxAmount}
          </div>
        ) : (
          <></>
        )}
        <RadioButton
          options={paymentInstrumentOptions}
          defaultOptionId={PaymentInstrument.UPI.toString()}
          onSelect={(id) => setSelectedPaymentInstrument(Number(id))}
        />
        {/* if card is selected, then show a warning of lower discount */}
        {selectedPaymentInstrument === PaymentInstrument.CARD &&
          discountDifference > 0 && (
            <div className="rounded-lg border border-orange-500 bg-orange-100 p-4 text-orange-600">
              <p>
                ⚠️ Payments through card have {discountDifference.toFixed(2)}%
                lower discount than UPI.
              </p>
            </div>
          )}
        {/* if wallet is selected, then show a warning of lower discount */}
        {selectedPaymentInstrument === PaymentInstrument.WALLET &&
          discountDifference > 0 && (
            <div className="rounded-lg border border-orange-500 bg-orange-100 p-4 text-orange-600">
              <p>
                ⚠️ Payments through wallet have {discountDifference.toFixed(2)}%
                lower discount than UPI.
              </p>
            </div>
          )}
        <button
          className="w-full rounded-md bg-gradient-to-br from-yellow-500 to-orange-600 py-3 font-bold text-white hover:bg-orange-600 disabled:opacity-50"
          disabled={
            isLoading ||
            initiateOrderMutation.isPending ||
            verifyOrderMutation.isPending ||
            !!getUserError ||
            !!getGiftCardError ||
            giftCard?.purchaseLimitReached ||
            !isAmountValid
          }
          onClick={handlePlaceOrder}
        >
          Buy brand card
        </button>
      </div>
      <LoginModal open={loginModal} onClose={handleCloseLogin} />
      <EditUserDetailsModal
        open={detailsModal}
        onClose={handleCloseDetails}
        edit={false}
      />
    </>
  )
}

interface RazorpayPaymentOptions {
  razorpayOrderId: string
  userFullname?: string
  userContact?: string
  userEmail?: string
  userAddress?: string
  userPincode?: string
  amountINR: number // Amount in INR
  onSuccess: (razorpayPaymentId: string, razorpaySignature: string) => void
  onError: (errorMessage: string) => void
}

interface RazorpayPaymentResponse {
  razorpay_payment_id: string
  // docs say that we shouldn't use this even though it is returned
  razorpay_order_id: string
  razorpay_signature: string
}

const UPIRzpDisplayConfig = {
  display: {
    blocks: {
      banks: {
        name: 'Pay via UPI',
        instruments: [
          {
            method: 'upi',
          },
        ],
      },
    },
    sequence: ['block.banks'],
    preferences: {
      show_default_blocks: false,
    },
  },
}

const WalletRzpDisplayConfig = {
  display: {
    blocks: {
      banks: {
        name: 'Pay via Wallet',
        instruments: [
          {
            method: 'wallet',
            wallets: ['amazonpay'],
          },
        ],
      },
    },
    sequence: ['block.banks'],
    preferences: {
      show_default_blocks: false,
    },
  },
}

const CardOnlyRzpDisplayConfig = {
  display: {
    blocks: {
      banks: {
        name: 'Pay via Card',
        instruments: [
          {
            method: 'card',
          },
        ],
      },
    },
    sequence: ['block.banks'],
    preferences: {
      show_default_blocks: false,
    },
  },
}

function initiateRazorpayPayment(
  paymentInstrument: PaymentInstrument,
  options: RazorpayPaymentOptions,
) {
  const key_id = import.meta.env.VITE_APP_RAZORPAY_API_KEY
  const totalAmountInPaise = options.amountINR * 100

  const razorpayOptions = {
    key: key_id,
    order_id: options.razorpayOrderId,
    amount: totalAmountInPaise,
    currency: 'INR',
    name: 'Dealzy',
    description: 'Best deals on brands!',
    webview_intent: true,
    // this config makes only either UPI or card as the valid payment method
    config:
      paymentInstrument === PaymentInstrument.CARD
        ? CardOnlyRzpDisplayConfig
        : paymentInstrument === PaymentInstrument.WALLET
          ? WalletRzpDisplayConfig
          : UPIRzpDisplayConfig,
    handler: function (response: RazorpayPaymentResponse) {
      // Handle payment success
      options.onSuccess(
        response.razorpay_payment_id,
        response.razorpay_signature,
      )
    },
    prefill: {
      name: options.userFullname,
      contact: options.userContact,
      email: options.userEmail,
    },
    notes: {
      address: options.userAddress,
      pincode: options.userPincode,
    },
    hidden: {
      contact: true,
      email: true,
    },
    modal: {
      escape: false,
    },
  }

  // @ts-expect-error Razorpay does not give typing on window object
  const rzp = new window.Razorpay(razorpayOptions)
  interface RazorpayError {
    error: { description: string }
  }
  rzp.on('payment.failed', function (response: RazorpayError) {
    // Handle payment failure
    options.onError(response.error.description)
  })
  rzp.open()
}
