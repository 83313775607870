import { Button } from '@dealzy/components/ui/ui/button'
import LoginModal from '@dealzy/pages/LoginModal'
import React, { useState } from 'react'

const UnAuthProfile = () => {
  //Component Hooks
  //Component States
  const [open, setOpen] = useState(false)
  //Component Methods
  const handleClose = () => {
    setOpen(false)
  }
  //Component JSX
  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-background p-4">
      <h2 className="text-2xl font-bold text-foreground">Welcome to Dealzy</h2>
      <h4 className="text-lg font-medium text-muted-foreground">
        Instant discounts everytime you shop!
      </h4>
      <h5 className="mt-[20px] text-center text-sm text-muted-foreground">
        Please login to be use all of dealzy features,
        <br /> make purchases and view your cards.
      </h5>
      <Button className="mt-[20px]" onClick={() => setOpen(true)}>
        Login with Phone-Number
      </Button>
      <LoginModal open={open} onClose={() => handleClose()} />
    </div>
  )
}

export default UnAuthProfile
