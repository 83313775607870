import { useInfiniteQuery } from '@connectrpc/connect-query'
import Base from '@dealzy/Base'
import { OrderCard } from '@dealzy/components/OrderCard'
import { getOrders } from '@dealzy/gen/api-DealzyService_connectquery'
import { Loader } from 'lucide-react'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const Transactions = () => {
  //Component Hooks
  const navigate = useNavigate()
  const pageSize = 10
  const {
    data: orders,
    isLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    getOrders,
    { page: 1, pageSize },
    {
      pageParamKey: 'page',
      getNextPageParam: (lastPage, allPages) => {
        const canGetMore = lastPage && lastPage.orders.length === pageSize
        return canGetMore ? allPages.length + 1 : undefined
      },
    },
  )
  const loaderRef = useRef(null)
  //Component States

  useEffect(() => {
    const options = {
      root: null, // Using the viewport as the root
      rootMargin: '20px',
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage()
      }
    }, options)

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      loaderRef.current && observer.unobserve(loaderRef.current)
    }
  }, [fetchNextPage, hasNextPage, loaderRef])

  const handleOrderClick = (orderId: number) => {
    navigate('/my-orders/' + orderId)
  }
  //Component Methods
  //Component JSX
  return (
    <Base backTo="/profile">
      <div className="flex flex-col bg-white">
        <h2 className="border-b p-4 text-2xl font-bold">My Transactions</h2>
        <div>
          {isLoading ? (
            <div className="flex h-48 items-center justify-center">
              <Loader className="h-8 w-8 animate-spin" />
            </div>
          ) : error ? (
            <div className="flex flex-col items-center justify-center p-6 text-center text-red-500">
              <p className="font-semibold">Error loading orders!</p>
            </div>
          ) : (
            <>
              {orders?.pages.map((page) =>
                page.orders.map((order) => (
                  <OrderCard
                    order={order}
                    handleOrderClick={handleOrderClick}
                  />
                )),
              )}
              {isFetchingNextPage && (
                <div className="flex h-48 items-center justify-center">
                  <Loader className="h-8 w-8 animate-spin" />
                </div>
              )}
              {hasNextPage && <div ref={loaderRef}>.</div>}
            </>
          )}
        </div>
      </div>
    </Base>
  )
}

export default Transactions
